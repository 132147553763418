import { TimeTracking } from "@farmact/model/src/model/TimeTracking";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import dayjs from "dayjs";
import React, { ComponentPropsWithoutRef } from "react";
import { calculateTimeTrackingAmount } from "../../../../../../../util/calculationUtils";
import { getSingularPluralTextFormatter } from "../../../../../../../util/formatters";
import {
    filterTimeTrackingsOnlyBreaks,
    filterTimeTrackingsWithoutBreak,
} from "../../../../../../../util/timeTrackingUtils";
import "../tableGroupTopLevel.scss";
import { useTableGroupContext } from "../../TableGroupContext";
import { getEarliestTimeTracking, getLatestTimeTracking } from "./tableGroupHeaderUtils";

interface ITableGroupHeaderProps extends ComponentPropsWithoutRef<"div"> { }

export const TableGroupHeader = (props: ITableGroupHeaderProps) => {
    const { open, setOpen, group } = useTableGroupContext();

    const earliestTimeTracking = getEarliestTimeTracking(group.timeTrackings);
    const latestTimeTracking = getLatestTimeTracking(group.timeTrackings);

    const breakTimeTrackings = filterTimeTrackingsOnlyBreaks(group.timeTrackings);
    const workTimeTrackings = filterTimeTrackingsWithoutBreak(group.timeTrackings);

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        setOpen(!open);
        props.onClick?.(event);
    };

    return (
        <div
            {...props}
            className={clsx("table-group-top-level", props.className, {
                "table-group-top-level--open": open,
                "table-group-top-level--closed": !open,
            })}
            onClick={handleClick}>
            <div className="table-group-top-level__cell table-group-top-level__date">
                <FontAwesomeIcon
                    icon={faChevronDown}
                    className={clsx("group-icon", {
                        "group-icon--open": open,
                        "group-icon--closed": !open,
                    })}
                />
                <span className="group-date__day">{formatDay(group.day)}</span>
                <span className="group-date__date">{formatDate(group.day)}</span>
            </div>
            <div className="table-group-top-level__cell table-group-top-level__work-time">
                {formatWorkTime(
                    earliestTimeTracking
                        ? new Date(earliestTimeTracking.startDateTime)
                        : dayjs(group.day).startOf("day").toDate()
                )}
                <span>-</span>
                {formatWorkTime(
                    latestTimeTracking
                        ? new Date(latestTimeTracking.endDateTime)
                        : dayjs(group.day).endOf("day").toDate()
                )}
            </div>
            <div className="table-group-top-level__cell table-group-top-level__time">
                {formatTotalWorkTime(workTimeTrackings)}
            </div>
            <div className="table-group-top-level__cell table-group-top-level__break">
                {formatBreakTime(breakTimeTrackings)}
            </div>
            <div className="table-group-top-level__cell table-group-top-level__total">
                {formatTotalWorkTime(workTimeTrackings)}
            </div>
            <div className="table-group-top-level__cell table-group-top-level__activities">
                {formatActivitiesCount(workTimeTrackings)}
            </div>
            <div className="table-group-top-level__cell table-group-top-level__notes">
                {formatNotesCount(group.timeTrackings.filter(tt => !!tt.note).length)}
            </div>
            <div className="table-group-top-level__cell" />
        </div>
    );
};

function formatDay(day: Date): string {
    return dayjs(day).locale("de").format("dd.");
}

function formatDate(day: Date): string {
    return dayjs(day).format("DD.MM");
}

function formatWorkTime(date: Date): string {
    return dayjs(date).format("HH:mm");
}

function formatBreakTime(timeTrackings: TimeTracking[]): string {
    const totalBreakTimeMinutes = calculateTimeTrackingAmount(timeTrackings, "minutes");

    const breakTimeHours = Math.floor(totalBreakTimeMinutes / 60);
    const breakTimeMinutes = Math.round(totalBreakTimeMinutes % 60);

    if (breakTimeHours > 0 && breakTimeMinutes > 0) {
        return `${breakTimeHours} h ${breakTimeMinutes} min`;
    } else if (breakTimeHours > 0) {
        return `${breakTimeHours} h`;
    }
    return `${breakTimeMinutes} min`;
}

function formatTotalWorkTime(timeTrackings: TimeTracking[]): string {
    const totalTime = calculateTimeTrackingAmount(timeTrackings);

    const roundedMinutes = Math.round(totalTime);
    const hours = Math.floor(roundedMinutes / 60);
    const minutes = roundedMinutes % 60;

    return `${hours}:${minutes.toString().padStart(2, "0")} h`;
}

const activitySPFormatter = getSingularPluralTextFormatter("Aktivität", "Aktivitäten");

function formatActivitiesCount(timeTrackings: TimeTracking[]): string {
    return `${timeTrackings.length} ${activitySPFormatter(timeTrackings.length)}`;
}

const noteSPFormatter = getSingularPluralTextFormatter("Notiz", "Notizen");

function formatNotesCount(numberOfNotes: number): string {
    return `${numberOfNotes} ${noteSPFormatter(numberOfNotes)}`;
}
