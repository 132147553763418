import { Currency, FALLBACK_CURRENCY } from "./Currency";
import { Employee } from "./Employee";
import { OperatingUnit } from "./OperatingUnit";

export const MIN_CUSTOMER_NUMBER = 10001;
export const DEFAULT_ORDER_NUMBERS_START = 700_000;

export enum PaymentMethodStatus {
    INITIAL = "INITIAL",
    READY_FOR_SETUP = "READY_FOR_SETUP",
    AVAILABLE = "AVAILABLE",
}

type AppCompanyCounters = {
    nextOrderNumber: number;
    nextCustomerNumber: number;
};

export class AppCompany {
    public id: string;
    public archived: boolean;
    public name?: string;
    public paymentMethodStatus: PaymentMethodStatus;
    public defaultUnitId: OperatingUnit["id"];
    public settings: CompanyWideSettings;
    public counters: AppCompanyCounters;

    constructor(initialValues?: Partial<AppCompany>) {
        this.id = initialValues?.id ?? "";
        this.archived = initialValues?.archived ?? false;
        this.name = initialValues?.name;
        this.paymentMethodStatus = initialValues?.paymentMethodStatus ?? PaymentMethodStatus.INITIAL;
        this.defaultUnitId = initialValues?.defaultUnitId ?? "";
        this.settings = {
            ...DEFAULT_APPCOMPANY_SETTINGS,
            ...initialValues?.settings,
        };
        this.counters = {
            nextCustomerNumber: initialValues?.counters?.nextCustomerNumber ?? MIN_CUSTOMER_NUMBER,
            nextOrderNumber: initialValues?.counters?.nextOrderNumber ?? DEFAULT_ORDER_NUMBERS_START,
        };
    }
}

export type CompanyWideSettings = {
    showVatOnReceipts: boolean;
    showPageNumbersOnReceipts: boolean;
    showLogoOnEveryReceiptPage: boolean;
    currency: Currency;
    showNoteQueryForInternalWork: boolean;
    enableMultiCustomerOrders: boolean;
    showColleaguesInTaskDetailsPage: ShowColleaguesSetting;
    /**
     * @deprecated
     */
    showGeolocationTrackingsInTaskDetailsPage: ShowGeolocationTrackingsSetting;
    showOwnGeolocationTrackingsInTaskDetailsPage: ShowOwnGeolocationTrackingsSetting;
    showColleaguesGeolocationTrackingsInTaskDetailsPage: ShowColleagueGeolocationTrackingsSetting;
    specificEmployeesForGeolocationTracking: Employee["id"][];
    enableCheckedOrderStatus: boolean;
    enableMaschinenring: boolean;
    enableProjects: boolean;
    employeeSettings: EmployeeSettings;
};

export interface EmployeeSettings {
    timeTrackingEditRestriction: TimeTrackingRestriction;
}

export type TimeTrackingRestriction =
    | { type: TimeTrackingRestrictionType.FULL }
    | { type: TimeTrackingRestrictionType.NONE }
    | { type: TimeTrackingRestrictionType.DAYS; days: number };

export enum TimeTrackingRestrictionType {
    /**
     *  No restriction,
     *  employees can edit time trackings.
     */
    NONE = "NONE",
    /**
     * Always restricted,
     * employees can not edit time trackings.
     */
    FULL = "FULL",
    /**
     * Restricted for days,
     * employees can edit time trackings for the last X days
     */
    DAYS = "DAYS",
}

export enum ShowColleaguesSetting {
    NONE = "NONE",
    SAME_CUSTOMER = "SAME_CUSTOMER",
    ALL = "ALL",
}

export enum ShowGeolocationTrackingsSetting {
    NONE = "NONE",
    OWN = "OWN",
    SPECIFIC_EMPLOYEES_SAME_CUSTOMER = "SPECIFIC_EMPLOYEES_SAME_CUSTOMER",
    SAME_CUSTOMER = "SAME_CUSTOMER",
}

export enum ShowOwnGeolocationTrackingsSetting {
    NONE = "NONE",
    LAST_24_HOURS = "LAST_24_HOURS",
    ONLY_ACTIVE = "ONLY_ACTIVE",
    ALL = "ALL",
}

export enum ShowColleagueGeolocationTrackingsSetting {
    NONE = "NONE",
    SPECIFIC_EMPLOYEES_SAME_CUSTOMER = "SPECIFIC_EMPLOYEES_SAME_CUSTOMER",
    SAME_CUSTOMER = "SAME_CUSTOMER",
}

const DEFAULT_EMPLOYEE_SETTINGS: EmployeeSettings = {
    timeTrackingEditRestriction: {
        type: TimeTrackingRestrictionType.DAYS,
        days: 7,
    },
};

export const DEFAULT_APPCOMPANY_SETTINGS: CompanyWideSettings = {
    showVatOnReceipts: false,
    showPageNumbersOnReceipts: true,
    showLogoOnEveryReceiptPage: true,
    currency: FALLBACK_CURRENCY,
    showNoteQueryForInternalWork: false,
    enableMultiCustomerOrders: false,
    showColleaguesInTaskDetailsPage: ShowColleaguesSetting.SAME_CUSTOMER,
    showGeolocationTrackingsInTaskDetailsPage: ShowGeolocationTrackingsSetting.SAME_CUSTOMER,
    showOwnGeolocationTrackingsInTaskDetailsPage: ShowOwnGeolocationTrackingsSetting.ALL,
    showColleaguesGeolocationTrackingsInTaskDetailsPage: ShowColleagueGeolocationTrackingsSetting.SAME_CUSTOMER,
    specificEmployeesForGeolocationTracking: [],
    enableCheckedOrderStatus: false,
    enableMaschinenring: false,
    enableProjects: false,
    employeeSettings: DEFAULT_EMPLOYEE_SETTINGS,
};

export function getShowColleaguesSettingName(setting: ShowColleaguesSetting) {
    switch (setting) {
        case ShowColleaguesSetting.NONE:
            return "Keine";
        case ShowColleaguesSetting.SAME_CUSTOMER:
            return "Gleicher Kunde";
        case ShowColleaguesSetting.ALL:
            return "Alle";
    }
}

export function getShowOwnGeolocationTrackingsSettingName(setting: ShowOwnGeolocationTrackingsSetting) {
    switch (setting) {
        case ShowOwnGeolocationTrackingsSetting.NONE:
            return "Keine";
        case ShowOwnGeolocationTrackingsSetting.ALL:
            return "Alle";
        case ShowOwnGeolocationTrackingsSetting.LAST_24_HOURS:
            return "Letzte 24 Stunden";
        case ShowOwnGeolocationTrackingsSetting.ONLY_ACTIVE:
            return "Aktiver Auftrag";
    }
}

export function getShowColleaguesGeolocationTrackingsSettingName(setting: ShowColleagueGeolocationTrackingsSetting) {
    switch (setting) {
        case ShowColleagueGeolocationTrackingsSetting.NONE:
            return "Keine";
        case ShowColleagueGeolocationTrackingsSetting.SAME_CUSTOMER:
            return "Gleicher Kunde";
        case ShowColleagueGeolocationTrackingsSetting.SPECIFIC_EMPLOYEES_SAME_CUSTOMER:
            return "Spezifische Mitarbeiter beim gleichen Kunden";
    }
}
