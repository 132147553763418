import dayjs from "dayjs";
import { Customer } from "./Customer";
import Field from "./Field";
import { Order } from "./Order";
import { InternalWorkType } from "./workTypes/InternalWorkType";
import { OrderWorkType } from "./workTypes/OrderWorkType";

export class TimeTracking {
    public id: string;
    public appUserId: string;
    public employeeId: string;
    public order: TimeTrackingOrder | null;
    public name: string;
    public internalWorkType: InternalWorkType | null;
    public orderWorkType: OrderWorkType | null;
    public startDateTime: string;
    public endDateTime: string;
    public note: string;

    constructor(initialValues?: Partial<TimeTracking>) {
        this.id = initialValues?.id ?? "";
        this.appUserId = initialValues?.appUserId ?? "";
        this.employeeId = initialValues?.employeeId ?? "";
        this.order = initialValues?.order ?? null;
        this.name = initialValues?.name ?? "";
        this.internalWorkType = initialValues?.internalWorkType ?? null;
        this.orderWorkType = initialValues?.orderWorkType ?? null;
        this.startDateTime = initialValues?.startDateTime ?? dayjs().second(0).millisecond(0).toISOString();
        this.endDateTime = initialValues?.endDateTime ?? dayjs(this.startDateTime).add(1, "hour").toISOString();
        this.note = initialValues?.note ?? "";
    }
}

export interface TimeTrackingOrder {
    orderId: Order["id"];
    customerId: Customer["id"] | null;
    mapStructure: AnyTimeTrackingMapStructure | null;
}

export enum TimeTrackingMapStructureType {
    FIELD = "FIELD",
}

export interface TimeTrackingField {
    type: TimeTrackingMapStructureType.FIELD;
    fieldId: Field["id"];
}

export type AnyTimeTrackingMapStructure = TimeTrackingField;
