import { UpdateData } from "firebase/firestore";
import { notNullish } from "../utils/array";
import { generateSearchableSubstrings } from "../utils/generateSearchableSubstrings";
import { CountryCode } from "./CountryCode";
import { Vertex } from "./Field";
import { OperatingUnit } from "./OperatingUnit";
import { PaymentType } from "./PaymentType";
import { Address } from "./Receipt";
import { SharingTokenType } from "./SharingToken";

export enum CustomerConnectionStatus {
    NOT_CONNECTED = "NOT_CONNECTED",
    INVITED = "INVITED",
    CONNECTED = "CONNECTED",
}

export enum Salutation {
    MR = "MR",
    MRS = "MRS",
    DOCTOR = "DOCTOR",
    PROFESSOR = "PROFESSOR",
    COMPANY = "COMPANY",
}

export type CustomerContactPerson = {
    salutation: Salutation | null;
    firstName: string | null;
    lastName: string;
};

export function getSalutationName(salutation: CustomerContactPerson["salutation"]): string {
    switch (salutation) {
        case Salutation.MR:
            return "Herrn";
        case Salutation.MRS:
            return "Frau";
        case Salutation.DOCTOR:
            return "Dr.";
        case Salutation.PROFESSOR:
            return "Prof.";
        case Salutation.COMPANY:
            return "Firma";
    }
    return "";
}

export type CustomerPaymentInfos = {
    paymentType: PaymentType;
    paymentDueDays: number;
    discountPercent: number;
    cashDiscountPercent: number;
    cashDiscountDueDays: number;
};

export const DEFAULT_CUSTOMER_PAYMENT_INFOS: CustomerPaymentInfos = {
    paymentType: PaymentType.INVOICE,
    paymentDueDays: 30,
    discountPercent: 0,
    cashDiscountPercent: 0,
    cashDiscountDueDays: 0,
};

export const DEFAULT_CUSTOMER_CONTACT_PERSON: CustomerContactPerson = {
    salutation: null,
    firstName: null,
    lastName: "",
};

export class Customer {
    public active: boolean;
    public addressCity: string;
    public addressStreet: string;
    public addressZip: string;
    public addressCountryCode: CountryCode;
    public alias: string;
    public appUserId: string | null;
    public archived: boolean;
    public buyerReference: string; // German "Leitstellen-ID"
    public cashDiscountDueDays: number | null; // Skontofrist
    public cashDiscountPercent: number | null; // Skonto
    public companyNumber: string; //Betriebsnummer
    public connectionStatus: CustomerConnectionStatus;
    public contactPerson: CustomerContactPerson | null;
    public contractExpireNotification: string | null; // YYYY-MM-DD
    public customerNumber: number | null;
    public deliveryAddress: Address;
    public discountPercent: number | null; // Rabatt
    public enabledSharingTypes: SharingTokenType[];
    public id: string;
    public latLng: Vertex | null;
    public mail: string;
    public mobilePhoneNumber: string;
    public name: string;
    public note: string;
    public operatingUnitIds: Array<OperatingUnit["id"]>;
    public paymentCollectionThroughMaschinenring: boolean;
    public paymentDueDays: number | null; // Zahlungsziel
    public paymentNotes: string;
    public paymentType: PaymentType | null;
    public phone: string;
    public salesTaxId: string;
    /**
     * only for use in queries, set via triggers
     */
    public _searchableSubstrings: string[];

    public readonly displayName: string;
    public readonly ordersWithoutBill: OrdersWithoutBill;
    public readonly ordersWithoutDeliveryNote: OrdersWithoutDeliveryNote;

    constructor(initialValues?: Partial<Customer>) {
        this.active = initialValues?.active ?? true;
        this.addressStreet = initialValues?.addressStreet ?? "";
        this.addressZip = initialValues?.addressZip ?? "";
        this.addressCity = initialValues?.addressCity ?? "";
        this.addressCountryCode = initialValues?.addressCountryCode ?? "DE";
        this.alias = initialValues?.alias ?? "";
        this.appUserId = initialValues?.appUserId ?? null;
        this.archived = initialValues?.archived ?? false;
        this.buyerReference = initialValues?.buyerReference ?? "";
        this.cashDiscountDueDays = initialValues?.cashDiscountDueDays ?? null;
        this.cashDiscountPercent = initialValues?.cashDiscountPercent ?? null;
        this.companyNumber = initialValues?.companyNumber ?? "";
        this.connectionStatus = initialValues?.connectionStatus ?? CustomerConnectionStatus.NOT_CONNECTED;
        this.contactPerson = initialValues?.contactPerson
            ? { ...DEFAULT_CUSTOMER_CONTACT_PERSON, ...initialValues.contactPerson }
            : null;
        this.contractExpireNotification = initialValues?.contractExpireNotification ?? null;
        this.customerNumber = initialValues?.customerNumber ?? null;
        this.discountPercent = initialValues?.discountPercent ?? null;
        this.deliveryAddress = initialValues?.deliveryAddress ?? {
            name: "",
            city: "",
            zip: "",
            street: "",
            countryCode: "DE",
        };
        this.displayName =
            initialValues?.displayName ||
            initialValues?.name ||
            [initialValues?.contactPerson?.lastName, initialValues?.contactPerson?.firstName].filter(Boolean).join(" ");
        this.enabledSharingTypes = initialValues?.enabledSharingTypes ?? [];
        this.id = initialValues?.id ?? "";
        this.latLng = initialValues?.latLng ?? null;
        this.mobilePhoneNumber = initialValues?.mobilePhoneNumber ?? "";
        this.mail = initialValues?.mail ?? "";
        this.name = initialValues?.name ?? "";
        this.note = initialValues?.note ?? "";
        this.operatingUnitIds = initialValues?.operatingUnitIds ?? [];
        this.paymentCollectionThroughMaschinenring = initialValues?.paymentCollectionThroughMaschinenring ?? false;
        this.paymentNotes = initialValues?.paymentNotes ?? "";
        this.paymentType = initialValues?.paymentType ?? null;
        this.paymentDueDays = initialValues?.paymentDueDays ?? null;
        this.phone = initialValues?.phone ?? "";
        this.salesTaxId = initialValues?.salesTaxId ?? "";
        this._searchableSubstrings = initialValues?._searchableSubstrings ?? [];
        this.ordersWithoutBill = initialValues?.ordersWithoutBill ?? {};
        this.ordersWithoutDeliveryNote = initialValues?.ordersWithoutDeliveryNote ?? {};
    }
}

export type OrdersWithoutBill = Record<OperatingUnit["id"], boolean>;
export type OrdersWithoutDeliveryNote = Record<OperatingUnit["id"], boolean>;

export function isCustomer(object: any): object is Customer {
    return (
        typeof object === "object" &&
        typeof object.addressStreet === "string" &&
        typeof object.addressZip === "string" &&
        typeof object.addressCity === "string"
    );
}

export function generateSearchableSubstringsForCustomer(customer: Partial<Customer>) {
    const searchableCustomerAttributes: string[] = [
        customer.name,
        customer.alias,
        customer.customerNumber?.toString(),
        customer.contactPerson?.firstName,
        customer.contactPerson?.lastName,
        customer.addressCity,
    ].filter(notNullish);

    return generateSearchableSubstrings(searchableCustomerAttributes);
}

export type WriteableCustomer = {
    -readonly [Key in keyof Customer]: Customer[Key];
};

export function preparePartialCustomerForFirestore(customer: UpdateData<Customer>): UpdateData<Customer> {
    const prepared: UpdateData<WriteableCustomer> = { ...customer };

    delete prepared.ordersWithoutBill;
    delete prepared.ordersWithoutDeliveryNote;

    return prepared;
}
