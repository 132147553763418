import React, { useContext } from "react";
import { AppUser, CustomAppUserClaims } from "@farmact/model/src/model/AppUser";
import { Employee } from "@farmact/model/src/model/Employee";
import { User } from "firebase/auth";

interface IUserContext {
    authUser?: User | null;
    appUser?: AppUser;
    employee?: Employee;
    isLoading: boolean;
    customClaims?: CustomAppUserClaims;
}

export const UserContext = React.createContext<IUserContext>({
    authUser: undefined,
    appUser: undefined,
    employee: undefined,
    customClaims: undefined,
    isLoading: true,
});

export function useUserContext() {
    return useContext(UserContext);
}
