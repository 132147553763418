import { TimeTracking } from "@farmact/model/src/model/TimeTracking";
import { useAppCompanyContext } from "../../components/authentication/AppCompanyContext";
import { useRole } from "./useRole";
import { useMemo } from "react";
import { Role } from "@farmact/model/src/model/Role";
import { TimeTrackingRestrictionType } from "@farmact/model/src/model/AppCompany";
import dayjs from "dayjs";

interface UseCanEditTimeTrackingReturn {
    canEditTimeTracking: boolean;
    hint: string;
}

export function useCanEditTimeTracking(timeTracking: TimeTracking | null): UseCanEditTimeTrackingReturn {
    const { role } = useRole();
    const { appCompany } = useAppCompanyContext();

    const isEmployeeOrTemporaryWorker = role
        ? [Role.EMPLOYEE, Role.TEMPORARY_WORKER, Role.PLANNER].includes(role)
        : true;
    const restriction = appCompany?.settings.employeeSettings.timeTrackingEditRestriction;
    const canEditTimeTracking = useMemo(() => {
        if (!timeTracking) {
            return false;
        }
        if (!restriction) {
            return false;
        }
        if (!isEmployeeOrTemporaryWorker) {
            return true;
        }
        if (restriction.type === TimeTrackingRestrictionType.FULL) {
            return false;
        }
        if (restriction.type === TimeTrackingRestrictionType.NONE) {
            return true;
        }
        if (restriction.type === TimeTrackingRestrictionType.DAYS) {
            return Math.abs(dayjs().diff(dayjs(timeTracking.startDateTime), "days")) < restriction.days;
        }
        return false;
    }, [restriction, isEmployeeOrTemporaryWorker, timeTracking]);
    const hint = useMemo(() => {
        if (restriction?.type === TimeTrackingRestrictionType.FULL) {
            return "Zeiterfassungen dürfen nicht nachträglich bearbeitet oder hinzugefügt werden. Wende dich bitte an deinen Vorgesetzten.";
        }
        if (restriction?.type === TimeTrackingRestrictionType.DAYS) {
            return `Zeiterfassungen, die mehr als ${restriction.days} Tage in der Vergangenheit oder in der Zukunft liegen, müssen von einem Vorgesetzten hinzugefügt oder bearbeitet werden.`;
        }
        return "";
    }, [restriction]);

    return { canEditTimeTracking, hint };
}
