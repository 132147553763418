import { Employee } from "@farmact/model/src/model/Employee";
import { useContext } from "react";
import { getSingleSelectChangeHandler, SingleSelectProps, useSelectItems } from "../../../util/select";
import OrganizationContext from "../../organization/context/OrganizationContext";
import { ISingleSelectProps, SingleSelect } from "../SingleSelect/SingleSelect";
import { createEmployeeSelectItem, EmployeeOrId, getEmployeeId } from "./employeeInputsUtils";

interface ISingleEmployeeSelectOwnProps extends Omit<ISingleSelectProps, "data" | "value" | "onChange"> {
    /**
     * Specify employees that can be selected. By default `employees` from context are used
     */
    employees?: Employee[];

    value: EmployeeOrId | null | undefined;
}
export type ISingleEmployeeSelectProps = SingleSelectProps<Employee, ISingleEmployeeSelectOwnProps>;

export const SingleEmployeeSelect = (props: ISingleEmployeeSelectProps) => {
    const {
        employees: controlledEmployees,
        itemCreator = createEmployeeSelectItem,
        onValueChange,
        onModelChange,
        value,
        placeholder = "Mitarbeiter auswählen",
        ...restProps
    } = props;

    const { employees: uncontrolledEmployees } = useContext(OrganizationContext);
    const employees = controlledEmployees || uncontrolledEmployees;

    const items = useSelectItems(employees, itemCreator);

    return (
        <SingleSelect
            {...restProps}
            placeholder={placeholder}
            data={items}
            value={value ? getEmployeeId(value) : null}
            onChange={getSingleSelectChangeHandler(employees, {
                onValueChange,
                onModelChange,
            })}
        />
    );
};
