import { TimeTracking } from "@farmact/model/src/model/TimeTracking";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import dayjs from "dayjs";
import { ComponentPropsWithoutRef, useState } from "react";
import { EditTimeTrackingModal } from "../../../../../timeTracking/EditTimeTrackingModal/EditTimeTrackingModal";
import { useTableGroupContext } from "../TableGroupContext";
import { DateCell } from "../TableGroupRow/DateCell/DateCell";
import "./tableGroupAddRow.scss";
import { Employee } from "@farmact/model/src/model/Employee";

interface ITableGroupAddRowProps extends ComponentPropsWithoutRef<"div"> {
    employee: Employee
    /**
     * Whether the user can add a timetracking for another user.
     */
    canChangeEmployee: boolean;
}

/**
 * Table row to add a time tracking
 * @param props
 * @returns
 */
export const TableGroupAddRow = (props: ITableGroupAddRowProps) => {
    const { group } = useTableGroupContext();
    const { canChangeEmployee, employee, ...restProps } = props;

    const [addModalOpen, setAddModalOpen] = useState(false);
    const [timeTrackingToAdd, setTimeTrackingToAdd] = useState<TimeTracking | null>(null);

    const openAddTimeTrackingModal = () => {
        setAddModalOpen(true);
        setTimeTrackingToAdd(
            new TimeTracking({
                startDateTime: dayjs(group.day).startOf("day").toISOString(),
                endDateTime: dayjs(group.day).endOf("day").toISOString(),
                employeeId: employee.id,
                appUserId: employee.appUserId ?? "",
            })
        );
    };
    const dismissAddTimeTrackingModal = () => {
        setAddModalOpen(false);
        setTimeTrackingToAdd(null);
    };

    const handleTimeTrackingChange = (updateData: Partial<TimeTracking>) => {
        setTimeTrackingToAdd(prev => {
            return new TimeTracking({
                ...(prev ?? {}),
                ...updateData,
            });
        });
    };

    return (
        <>
            <div {...restProps} className={clsx("table-group-add-row", props.className)}>
                <DateCell />

                <button className="table-group-add-row__add-cells" onClick={openAddTimeTrackingModal}>
                    <FontAwesomeIcon icon={faPlus} />
                    <span>Arbeitszeit hinzufügen</span>
                </button>
            </div>

            <EditTimeTrackingModal
                role="add"
                timeTracking={timeTrackingToAdd}
                onFormChange={handleTimeTrackingChange}
                isOpen={addModalOpen}
                onDidDismiss={dismissAddTimeTrackingModal}
                allowEmployeeInput={canChangeEmployee}
            />
        </>
    );
};
