import { useMemo } from "react";
import { useStableArrayById } from "../../../util/customHooks/useStableArray";
import { useCustomersMarkers } from "../../../util/customHooks/customers/useCustomersMarkers";
import { Order } from "@farmact/model/src/model/Order";

export function useCustomerAndOrderMarkers(order: Order | undefined) {
    const { markers: customersMarkers } = useCustomersMarkers(order?.customerIds ?? []);
    const stableOrderMarkers = useStableArrayById(order?.markers ?? []);

    return useMemo(
        () => stableOrderMarkers.reduce(
            (acc, marker) => {
                if (acc.every(m => m.id !== marker.id)) {
                    acc.push(marker);
                }
                return acc;
            },
            [...customersMarkers]
        ),
        [customersMarkers, stableOrderMarkers]
    );
}
