import { Customer } from "@farmact/model/src/model/Customer";
import { Marker } from "@farmact/model/src/model/Marker";
import { Query, query, where } from "firebase/firestore";
import { CustomerOrId, getCustomerId } from "../../../components/inputs/customerInputs/customerInputsUtils";
import { Firebase } from "../../../firebase";
import { useMergedQueryData } from "../../../firebase/utils/useMergedQueryData/useMergedQueryData";
import { useStableIdArray } from "../useStableArray";

interface UseCustomersMarkersReturn {
    markers: Marker[];
    loading: boolean;
}

export function useCustomersMarkers(customers: CustomerOrId[]): UseCustomersMarkersReturn {
    const customerIds = useStableIdArray(customers.map(getCustomerId));

    const [markers, loading] = useMergedQueryData(customerIds, getCustomerMarkersQuery);

    return {
        markers,
        loading,
    };
}

function getCustomerMarkersQuery(customerIds: Array<Customer["id"]>): Query<Marker> {
    return query(Firebase.instance().getAllMarkers({companyId: undefined}), where("customerId", "in", customerIds));
}
