import {
    AnyPriceStructure,
    PriceStructureType,
    SimplePriceStructure,
    TieredPriceStructure,
    VolumePriceStructure,
} from "./services/ServicePriceBlocks";

export interface ResourcePrice<T extends ResourcePriceCalculationType> {
    id: string;
    name: string;
    calculationType: T;
    variantsType: ResourcePriceVariantsType;
    costPerUnit?: number;
    pricePerUnit?: AnyResourcePriceStructure;
    surchargePercent?: number;
    variants?: ResourcePrice<T>[];
}

export interface ResourceTieredPriceStructure extends TieredPriceStructure {
    id: string;
}
export interface ResourceVolumePriceStructure extends VolumePriceStructure {
    id: string;
}

export type AnyResourcePriceStructure =
    | SimplePriceStructure
    | ResourceTieredPriceStructure
    | ResourceVolumePriceStructure;
export interface FixedResourcePrice extends ResourcePrice<ResourcePriceCalculationType.FIXED> {}

export interface SurchargeResourcePrice extends ResourcePrice<ResourcePriceCalculationType.SURCHARGE> {}

export interface SimpleResourcePrice<T extends ResourcePriceCalculationType> extends ResourcePrice<T> {
    variantsType: ResourcePriceVariantsType.SIMPLE;
    costPerUnit: number;
}

export interface VariantsResourcePrice<T extends ResourcePriceCalculationType> extends ResourcePrice<T> {
    variantsType: ResourcePriceVariantsType.VARIANTS;
    variants: ResourcePrice<T>[];
}

export type SimpleFixedResourcePrice = SimpleResourcePrice<ResourcePriceCalculationType.FIXED> & {
    pricePerUnit: AnyResourcePriceStructure;
};

export type SimpleSurchargeResourcePrice = SimpleResourcePrice<ResourcePriceCalculationType.SURCHARGE> & {
    surchargePercent: number;
};

export type VariantsFixedResourcePrice = VariantsResourcePrice<ResourcePriceCalculationType.FIXED>;

export type VariantsSurchargeResourcePrice = VariantsResourcePrice<ResourcePriceCalculationType.SURCHARGE>;

export interface ResourceVariantIdentifier {
    resourceId: string;
    variantId: string;
}

export enum ResourcePriceCalculationType {
    FIXED = "FIXED",
    SURCHARGE = "SURCHARGE",
}

export enum ResourcePriceVariantsType {
    SIMPLE = "SIMPLE",
    VARIANTS = "VARIANTS",
}

export function isSimplePriceStructure(priceBlock: AnyPriceStructure | undefined): priceBlock is SimplePriceStructure {
    return priceBlock?.type === PriceStructureType.SIMPLE;
}
export function isTiredOrVolumePriceStructe(
    priceBlock: AnyPriceStructure | undefined
): priceBlock is TieredPriceStructure | VolumePriceStructure {
    return priceBlock?.type === PriceStructureType.TIERED || priceBlock?.type === PriceStructureType.VOLUME;
}
