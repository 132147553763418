import { TimeTracking } from "@farmact/model/src/model/TimeTracking";
import clsx from "clsx";
import { ComponentPropsWithoutRef } from "react";
import { DateCell } from "./DateCell/DateCell";
import { TableCells } from "./TableCells/TableCells";
import "./tableGroupRow.scss";

interface ITableGroupRowProps extends ComponentPropsWithoutRef<"div"> {
    timeTracking: TimeTracking;
    allowEmployeeChange: boolean;
}

export const TableGroupRow = (props: ITableGroupRowProps) => {
    const { timeTracking, allowEmployeeChange, ...restProps } = props;

    return (
        <div {...restProps} className={clsx("table-group-row", props.className)}>
            <DateCell />
            <TableCells timeTracking={timeTracking} allowEmployeeChange={allowEmployeeChange} />
        </div>
    );
};
