import { Employee } from "@farmact/model/src/model/Employee";
import { ExtendedSelectItem } from "../SingleSelect/SelectItem/SelectItem";

export type EmployeeOrId = Employee | Employee["id"];

export function getEmployeeId(employeeOrId: EmployeeOrId): Employee["id"] {
    if (typeof employeeOrId === "string") {
        return employeeOrId;
    }

    return employeeOrId.id;
}

export function createEmployeeSelectItem(employee: Employee): ExtendedSelectItem {
    return {
        value: employee.id,
        label: `${employee.firstName} ${employee.lastName}`,
        disabled: !employee.active || employee.archived,
    };
}
