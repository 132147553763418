import clsx from "clsx";
import { ComponentPropsWithoutRef, CSSProperties } from "react";
import useResizeObserver from "use-resize-observer";
import { useTableGroupContext } from "../TableGroupContext";
import "./tableGroupBody.scss";

interface ITableGroupBodyProps extends ComponentPropsWithoutRef<"div"> { }

export const TableGroupBody = (props: ITableGroupBodyProps) => {
    const { children, ...restProps } = props;

    const { open } = useTableGroupContext();
    const { ref, height = 0 } = useResizeObserver();

    const animatedStyles: CSSProperties = {
        height: open ? height : 0,
        opacity: open ? 1 : 0,
        transform: `translateY(${open ? 0 : -5}px)`,
    };

    return (
        <div
            {...restProps}
            className={clsx(
                "table-group-body",
                {
                    "table-group-body--hidden": !open,
                },
                restProps.className
            )}
            style={{ ...restProps.style, ...animatedStyles }}>
            <div className="table-group-body__measure" ref={ref}>
                {children}
            </div>
        </div>
    );
};
