import { Overtime } from "@farmact/model/src/model/Overtime";
import { IonButton, IonButtons, IonContent, IonFooter, IonToolbar } from "@ionic/react";
import { useState } from "react";
import "./editOvertimeModal.scss";
import { useToastContext } from "../../../../../../../ToastContext";
import { recordError } from "../../../../../../../../util/recordError";
import { Firebase } from "../../../../../../../../firebase";
import { StandardModalHeader } from "../../../../../../../structure/StandardModalHeader/StandardModalHeader";
import { FarmActModal } from "../../../../../../../FarmActModal/FarmActModal";
import { FaItemGroup } from "../../../../../../../FaItemGroup/FaItemGroup";
import * as Item from "../../../../../../../FaItem";
import { DateInput } from "../../../../../../../inputs/DateInput/DateInput";
import { DurationInput } from "../../../../../../../inputs/DurationInput/DurationInput";
import { Textarea } from "../../../../../../../inputs/Textarea/Textarea";

interface IOvertimeModalProps {
    isOpen: boolean;
    onDismiss: () => void;
    overtime: Overtime;
}

export const EditOvertimeModal = (props: IOvertimeModalProps) => {
    const { onMessage } = useToastContext();
    const [overtime, setOvertime] = useState(new Overtime());
    const [previousOpen, setPreviousOpen] = useState(false);

    if (!previousOpen && props.isOpen) {
        setPreviousOpen(true);
        setOvertime({ ...props.overtime });
    }

    if (previousOpen && !props.isOpen) {
        setPreviousOpen(false);
        setOvertime(new Overtime());
    }

    const handleSaveOverTime = () => {
        try {
            Firebase.instance().updateOverTime(overtime.id, { ...overtime });
            onMessage("Überstunden gespeichert.", "success");
            props.onDismiss();
        } catch (error: any) {
            onMessage("Fehler beim speichern. Versuche es später erneut.", "danger");
            recordError("Error while trying to save overtime", { overtime, error });
        }
    };

    const handleOvertimeChange = (changedValues: Partial<Overtime>) => {
        setOvertime(prev => ({ ...prev, ...changedValues }));
    };

    const handleOvertimeDelete = () => {
        try {
            Firebase.instance().deleteOvertime(overtime.id);
            onMessage("Überstunden gelöscht", "success");
            props.onDismiss();
        } catch (error: any) {
            onMessage("Fehler beim löschen. Versuche es später erneut.", "danger");
            recordError("Error while trying to delete overtime", { overtime, error });
        }
    };
    return (
        <FarmActModal isOpen={props.isOpen} className="edit-overtime-modal" onDidDismiss={props.onDismiss}>
            <div className="default-modal-container edit-overtime-modal-container">
                <StandardModalHeader onCloseClick={props.onDismiss} title="Stundenkonto bearbeiten" />
                <IonContent>
                    <FaItemGroup>
                        <Item.Root>
                            <Item.Label>Datum</Item.Label>
                            <Item.Content>
                                <DateInput
                                    value={new Date(overtime.date)}
                                    onChange={value => handleOvertimeChange({ date: value?.toISOString() })}
                                    clearable={false}
                                />
                            </Item.Content>
                        </Item.Root>
                        <Item.Root>
                            <Item.Label tooltip="Negative Werte reduzieren das Stundenkonto.">Überstunden</Item.Label>
                            <Item.Content>
                                <DurationInput
                                    value={overtime.amount}
                                    onChange={amount => {
                                        handleOvertimeChange({ amount: amount ?? 0 });
                                    }}
                                />
                            </Item.Content>
                        </Item.Root>
                        <Item.Root>
                            <Item.Label>Notiz</Item.Label>
                            <Item.Content>
                                <Textarea
                                    placeholder="Notiz"
                                    value={overtime.note}
                                    onChange={event => {
                                        handleOvertimeChange({ note: event.currentTarget.value });
                                    }}
                                />
                            </Item.Content>
                        </Item.Root>
                    </FaItemGroup>
                </IonContent>
                <IonFooter>
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonButton color="dark" onClick={props.onDismiss}>
                                Verwerfen
                            </IonButton>
                        </IonButtons>
                        <IonButtons slot="end">
                            <IonButton color="danger" onClick={handleOvertimeDelete}>
                                Löschen
                            </IonButton>
                            <IonButton color="primary" onClick={handleSaveOverTime}>
                                Speichern
                            </IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonFooter>
            </div>
        </FarmActModal>
    );
};
