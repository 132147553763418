import clsx from "clsx";
import { ComponentPropsWithoutRef } from "react";
import "./tableHeaderRow.scss";

interface ITableHeaderRowProps extends ComponentPropsWithoutRef<"div"> { }

export const TableHeaderRow = (props: ITableHeaderRowProps) => {
    return (
        <div {...props} className={clsx("table-header-row", props.className)}>
            <div className="table-header-row__cell table-header-row__date">Datum</div>

            <div className="table-header-row__cell table-header-row__work-time">Zeitraum</div>
            <div className="table-header-row__cell table-header-row__time">Zeit</div>

            <div className="table-header-row__cell table-header-row__break">Pause</div>
            <div className="table-header-row__cell table-header-row__total">Gesamt</div>
            <div className="table-header-row__cell table-header-row__activity">Tätigkeit</div>
            <div className="table-header-row__cell table-header-row__note">Notiz</div>
            <div className="table-header-row__cell" />
        </div>
    );
};
