import { TimeTracking } from "@farmact/model/src/model/TimeTracking";
import dayjs from "dayjs";
import { CommonInternalWorkType } from "@farmact/model/src/model/workTypes/CommonInternalWorkType";
import { CommonOrderWorkType } from "@farmact/model/src/model/workTypes/CommonOrderWorkType";
import { getDoc } from "firebase/firestore";
import { getOrderWorkTypeName } from "@farmact/model/src/model/workTypes/OrderWorkType";
import { getInternalWorkTypeName } from "@farmact/model/src/model/workTypes/InternalWorkType";
import { Firebase } from "../firebase";

/**
 * Set start and end date time to start of minute to avoid confusion on receipts and analyses. Should be used when
 * manually adjusting time trackings.
 * Reason: if a time tracking has been recorded from 08:00:55 to 08:30:05, this would result in the time tracking being
 * displayed as 08:00 to 08:30 but only 29 minutes would show up on analyses and receipts which is counterintuitive.
 * @param timeTracking the time tracking to be normalized
 */
export function normalizeTimeTracking(timeTracking: TimeTracking) {
    return new TimeTracking({
        ...(timeTracking ?? {}),
        startDateTime: dayjs(timeTracking?.startDateTime).startOf("minute").toISOString(),
        endDateTime: dayjs(timeTracking?.endDateTime).startOf("minute").toISOString(),
    });
}

export async function generateTimeTrackingName(timeTracking: TimeTracking, firebase: Firebase): Promise<string> {
    if (timeTracking.name) {
        return timeTracking.name;
    }
    if (timeTracking.order?.orderId) {
        const order = (await getDoc(firebase.getOrderRef(timeTracking.order.orderId))).data();

        if (order?.name) {
            return `${order.name}: ${
                timeTracking.orderWorkType ? getOrderWorkTypeName(timeTracking.orderWorkType) : "Sonstiges"
            }`;
        }
    } else if (timeTracking.internalWorkType) {
        return `Intern: ${getInternalWorkTypeName(timeTracking.internalWorkType)}`;
    } else if (timeTracking.orderWorkType) {
        return `Auftrag: ${getOrderWorkTypeName(timeTracking.orderWorkType)}`;
    }

    return "Sonstiges";
}

export function getTimeTrackingOverlapsWithDay(day: Date, timeTracking: TimeTracking): boolean {
    const trackingStartMs = dayjs(timeTracking.startDateTime).valueOf();
    const trackingEndMs = dayjs(timeTracking.endDateTime).valueOf();

    const dayStartMs = dayjs(day).startOf("day").valueOf();
    const dayEndMs = dayjs(day).endOf("day").valueOf();

    // Tracking starts before and ends after day
    if (trackingStartMs <= dayStartMs && trackingEndMs >= dayEndMs) {
        return true;
    }

    const startsWithinDay = trackingStartMs >= dayStartMs && trackingStartMs <= dayEndMs;
    const endsWithinDay = trackingEndMs >= dayStartMs && trackingEndMs <= dayEndMs;

    return startsWithinDay || endsWithinDay;
}

export function filterTimeTrackingsWithoutBreak(timeTrackings: TimeTracking[]): TimeTracking[] {
    return timeTrackings.filter(timeTracking => {
        return (
            timeTracking.orderWorkType !== CommonOrderWorkType.PAUSE &&
            timeTracking.internalWorkType !== CommonInternalWorkType.PAUSE
        );
    });
}

export function filterTimeTrackingsOnlyBreaks(timeTrackings: TimeTracking[]): TimeTracking[] {
    return timeTrackings.filter(timeTracking => {
        return (
            timeTracking.orderWorkType === CommonOrderWorkType.PAUSE ||
            timeTracking.internalWorkType === CommonInternalWorkType.PAUSE
        );
    });
}
