import { v4 } from "uuid";
import { DEFAULT_VAT_PERCENT_POINTS } from "./Bill";
import { Customer } from "./Customer";
import { ResourceUsageSource } from "./Refuel";
import { ResourceUnit } from "./Resource";
import { AnyResourcePriceStructure } from "./ResourcePrice";
import { PriceStructureType } from "./services/ServicePriceBlocks";

export class ResourceUsage {
    public id: string;
    public amount: number;
    public customerId: Customer["id"] | null;
    public resourceId: string | null;
    public resourceVariantId: string | null;
    public resourceName: string;
    public resourceCostsPerUnit: number;
    public resourcePricePerUnit: AnyResourcePriceStructure;
    public resourceUnit: ResourceUnit;
    public source: ResourceUsageSource;
    public vatPercentPoints: number;
    public vskz_mr: string | null;
    public timestamp: string;

    constructor(initialValues?: Partial<ResourceUsage>) {
        this.id = initialValues?.id ?? v4();
        this.amount = initialValues?.amount ?? 0;
        this.customerId = initialValues?.customerId ?? null;
        this.resourceId = initialValues?.resourceId ?? null;
        this.resourceVariantId = initialValues?.resourceVariantId ?? null;
        this.resourceName = initialValues?.resourceName ?? "";
        this.resourceCostsPerUnit = initialValues?.resourceCostsPerUnit ?? 0;
        this.resourcePricePerUnit = initialValues?.resourcePricePerUnit ?? {
            price: 0,
            type: PriceStructureType.SIMPLE,
        };
        this.resourceUnit = initialValues?.resourceUnit ?? ResourceUnit.LITER;
        this.source = initialValues?.source ?? ResourceUsageSource.CUSTOMER;
        this.vatPercentPoints = initialValues?.vatPercentPoints ?? DEFAULT_VAT_PERCENT_POINTS;
        this.vskz_mr = initialValues?.vskz_mr ?? null;
        this.timestamp = initialValues?.timestamp ?? new Date().toISOString();
    }
}
