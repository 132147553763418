import Field from "@farmact/model/src/model/Field";
import { query, where } from "firebase/firestore";
import { useCallback } from "react";
import { Firebase } from "../../../firebase";
import { useMergedQueryData } from "../../../firebase/utils/useMergedQueryData/useMergedQueryData";
import { useStableIdArray } from "../useStableArray";

interface UseFieldsOptions {
    companyId?: string;
}
interface UseFieldsReturn {
    fields: Field[];
    loading: boolean;
}

export function useFields(fieldIds: string[], options: UseFieldsOptions = {}): UseFieldsReturn {
    const { companyId } = options;

    const stableFieldIds = useStableIdArray(fieldIds);

    const queryGenerator = useCallback(
        (fieldIds: string[]) => {
            return query(Firebase.instance().getAllFields({ companyId }), where("id", "in", fieldIds));
        },
        [companyId]
    );

    const [fields, loading] = useMergedQueryData(stableFieldIds, queryGenerator);

    return {
        fields,
        loading,
    };
}
