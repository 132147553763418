import { Customer } from "@farmact/model/src/model/Customer";
import { SelectItem } from "@mantine/core";
import { useMemo } from "react";
import { ExtendedSelectItem } from "../SingleSelect/SelectItem/SelectItem";

export type CustomerOrId = Customer | Customer["id"];

export function getCustomerId(customerOrId: CustomerOrId): Customer["id"] {
    if (typeof customerOrId === "string") {
        return customerOrId;
    }

    return customerOrId.id;
}

export function createCustomerSelectItem(customer: Customer): ExtendedSelectItem {
    return {
        value: customer.id,
        label: getCustomerSelectLabel(customer),
        disabled: !customer.active || customer.archived,
    };
}

export function getCustomerSelectLabel(customer: Customer): string {
    const name = customer.displayName;
    const alias = customer.alias ? `(${customer.alias})` : null;

    const lines = [name, alias];
    return lines.filter(Boolean).join(" ");
}

function getCustomerSearchable(customer: Customer): string {
    return [customer.name, customer.contactPerson?.firstName, customer.contactPerson?.lastName, customer.alias]
        .filter(Boolean)
        .join("")
        .toLowerCase();
}

function getCustomerSingleSelectFilter(customers: Customer[]) {
    return (search: string, option: SelectItem): boolean => {
        const customer = customers.find(customer => customer.id === option.value);
        if (!customer) {
            return false;
        }

        return getCustomerSearchable(customer).includes(search.toLowerCase());
    };
}

export function useCustomerSingleSelectFilter(customers: Customer[]) {
    return useMemo(() => {
        return getCustomerSingleSelectFilter(customers);
    }, [customers]);
}

function getCustomerMultiSelectFilter(customers: Customer[]) {
    return (search: string, selected: boolean, option: SelectItem): boolean => {
        if (selected) {
            return false;
        }

        const customer = customers.find(customer => customer.id === option.value);
        if (!customer) {
            return false;
        }

        return getCustomerSearchable(customer).includes(search.toLowerCase());
    };
}

export function useCustomerMultiSelectFilter(customers: Customer[]) {
    return useMemo(() => {
        return getCustomerMultiSelectFilter(customers);
    }, [customers]);
}

export const THRESHOLD_FOR_LOADING_CUSTOMERS = 300;
