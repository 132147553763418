export enum TaskRecordType {
    AREA = "AREA",
    TRACKS = "TRACKS",
    TONS = "TONS",
    CUBIC_METERS = "CUBIC_METERS",
    LITER = "LITER",
}

export function getTaskRecordTypeName(taskRecordType: TaskRecordType): string {
    switch (taskRecordType) {
        case TaskRecordType.AREA:
            return "Bearbeitete Fläche";
        case TaskRecordType.TRACKS:
            return "Bearbeitete Strecke";
        case TaskRecordType.TONS:
            return "Bearbeitete Tonnen";
        case TaskRecordType.CUBIC_METERS:
            return "Bearbeitete Kubikmeter";
        case TaskRecordType.LITER:
            return "Bearbeitete Liter";
    }
}
