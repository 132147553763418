import { TimeTracking } from "@farmact/model/src/model/TimeTracking";
import dayjs from "dayjs";
import "dayjs/locale/de";

export function getEarliestTimeTracking(timeTrackings: TimeTracking[]): TimeTracking | null {
    if (timeTrackings.length === 0) {
        return null;
    }

    const startSortedTrackings = [...timeTrackings].sort((a, b) => {
        return dayjs(a.startDateTime).valueOf() - dayjs(b.startDateTime).valueOf();
    });

    return startSortedTrackings[0];
}
export function getLatestTimeTracking(timeTrackings: TimeTracking[]): TimeTracking | null {
    if (timeTrackings.length === 0) {
        return null;
    }

    const endSortedTrackings = [...timeTrackings].sort((a, b) => {
        return dayjs(a.endDateTime).valueOf() - dayjs(b.endDateTime).valueOf();
    });

    return endSortedTrackings[endSortedTrackings.length - 1];
}
