import { Absence, getAbsenceIcon, getValueForAbsenceType } from "@farmact/model/src/model/Absence";
import dayjs from "dayjs";
import { IconWithTooltip } from "../../../../../../IconWithTooltip";
import "../tableGroupTopLevel.scss";

interface ITableGroupAbsenceProps {
    absence: Absence;
    date: string;
}

export const TableGroupAbsence = (props: ITableGroupAbsenceProps) => {
    return (
        <div {...props} className={"table-group-top-level table-group-absence"}>
            <div className="table-group-top-level__cell table-group-top-level__date">
                <IconWithTooltip icon={getAbsenceIcon(props.absence.type)} iconClassName="group-icon" color="primary">
                    {props.absence.note || getValueForAbsenceType(props.absence.type)}
                </IconWithTooltip>

                <span className="group-date__day">{formatDay(props.date)}</span>
                <span className="group-date__date">{formatDate(props.date)}</span>
            </div>
            <div className="table-group-top-level__cell table-group-top-level__work-time"></div>
            <div className="table-group-top-level__cell table-group-top-level__time"></div>
            <div className="table-group-top-level__cell table-group-top-level__break"></div>
            <div className="table-group-top-level__cell table-group-top-level__total"></div>
            <div className="table-group-top-level__cell table-group-top-level__activities"></div>
            <div className="table-group-top-level__cell table-group-top-level__notes">{props.absence.note}</div>
            <div className="table-group-top-level__cell"></div>
        </div>
    );
};
function formatDay(day: string): string {
    return dayjs(day).locale("de").format("dd.");
}

function formatDate(day: string): string {
    return dayjs(day).locale("de").format("DD.MM");
}
