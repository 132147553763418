import { AgriculturalContractorInternalWorkType } from "./AgriculturalContractorInternalWorkType";
import { CommonInternalWorkType } from "./CommonInternalWorkType";
import { LogisticsInternalWorkType } from "./LogisticsInternalWorkType";

export type InternalWorkType = AgriculturalContractorInternalWorkType | LogisticsInternalWorkType;
export const InternalWorkType = { ...LogisticsInternalWorkType, ...AgriculturalContractorInternalWorkType };

export function getInternalWorkTypeName(workType: InternalWorkType): string {
    switch (workType) {
        case CommonInternalWorkType.USE:
            return "Einsatzzeit";
        case CommonInternalWorkType.REPAIR:
            return "Reparieren";
        case CommonInternalWorkType.PAUSE:
            return "Pause";
        case CommonInternalWorkType.OTHER:
            return "Sonstiges";
        case AgriculturalContractorInternalWorkType.MOUNT:
            return "Rüsten";
        case AgriculturalContractorInternalWorkType.WASH:
            return "Waschen";
        case LogisticsInternalWorkType.LOAD:
            return "Beladen";
        case LogisticsInternalWorkType.UNLOAD:
            return "Entladen";
        default:
            return workType;
    }
}
