import { array, boolean, Describe, enums, literal, number, object, record, string, Struct, union } from "superstruct";
import { MachineCounterType } from "../MachineCounterTracking";
import { ScalePrice } from "../PriceBlocks/ScalePrice";
import { OrderWorkType } from "../workTypes/OrderWorkType";
import { AnyDriverQueryTemplate } from "./DriverQuery";

import { ServicePriceUnit } from "./Service";

export enum PriceStructureType {
    SIMPLE = "SIMPLE",
    TIERED = "TIERED",
    VOLUME = "VOLUME",
}

export type WorkTypeRestrictionMap = Record<OrderWorkType, boolean>;

export interface ServicePriceBlock {
    id: string; // uuid
    receiptText: string;
    price: AnyPriceStructure;
    workTypeRestriction: WorkTypeRestrictionMap | null;
    maschinenring: {
        fuelTarget: boolean;
        vskz: string | null;
    };
}

export interface CalculatedServicePriceBlock extends ServicePriceBlock {
    basis: ServicePriceUnit | MachineCounterType;
}

export interface DriverQueryServicePriceBlock extends ServicePriceBlock {
    driverQueryTemplate: AnyDriverQueryTemplate["id"];
}

export interface SimplePriceStructure {
    type: PriceStructureType.SIMPLE;
    price: number;
}

export interface TieredPriceStructure {
    type: PriceStructureType.TIERED;
    price: ScalePrice[];
}

export interface VolumePriceStructure {
    type: PriceStructureType.VOLUME;
    price: ScalePrice[];
}

export type AnyPriceStructure = SimplePriceStructure | TieredPriceStructure | VolumePriceStructure;

const ScalePriceStruct: Describe<ScalePrice> = object({
    id: string(),
    upperBound: union([number(), literal(null)]),
    price: number(),
});

export const VolumePriceStructureStruct: Describe<VolumePriceStructure> = object({
    type: literal(PriceStructureType.VOLUME),
    price: array(ScalePriceStruct),
});

export const TieredPriceStructureStruct: Describe<TieredPriceStructure> = object({
    type: literal(PriceStructureType.TIERED),
    price: array(ScalePriceStruct),
});

export const SimplePriceStructureStruct: Describe<SimplePriceStructure> = object({
    type: literal(PriceStructureType.SIMPLE),
    price: number(),
});

export const workTypeRestrictionMapStruct: Struct<Record<OrderWorkType, boolean>> = record(
    enums(Object.values(OrderWorkType)),
    boolean()
);
