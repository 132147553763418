import clsx from "clsx";
import { ComponentPropsWithRef, forwardRef } from "react";
import { ValidationError } from "../../../util/customHooks/validation/useValidation";
import { useFormControlContext } from "../../FormControlContext/FormControlContext";
import "./textarea.scss";

interface ITextareaOwnProps {
    fullWidth?: boolean;
    error?: ValidationError;
}
interface ITextareaProps extends ITextareaOwnProps, Omit<ComponentPropsWithRef<"textarea">, keyof ITextareaOwnProps> { }

export const Textarea = forwardRef<HTMLTextAreaElement, ITextareaProps>((props, forwardedRef) => {
    const { id: controlledId, disabled: controlledDisabled, fullWidth = false, error, ...restProps } = props;

    const { id: uncontrolledId, disabled: uncontrolledDisabled, state } = useFormControlContext();

    return (
        <textarea
            {...restProps}
            ref={forwardedRef}
            id={controlledId || uncontrolledId}
            disabled={controlledDisabled || uncontrolledDisabled}
            className={clsx(
                "farmact-textarea",
                {
                    "farmact-textarea--full-width": fullWidth,
                    //
                    "farmact-textarea--state-normal": state === "normal",
                    "farmact-textarea--state-warning": state === "warning",
                    "farmact-textarea--state-error": state === "error" || error,
                },
                props.className
            )}
        />
    );
});
