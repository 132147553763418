export enum PaymentType {
    INVOICE = "Rechnung",
    DIRECT_DEBIT = "Lastschrift"
}

export const getPaymentTypeName = (paymentType: PaymentType) => {
    switch (paymentType) {
        case PaymentType.INVOICE:
            return "Rechnung";
        case PaymentType.DIRECT_DEBIT:
            return "Lastschrift";
    }
};
