import { MachineCounterType } from "../MachineCounterTracking";
import { Resource } from "../Resource";
import { AnyDriverQueryTemplate, DriverQueryResourceWithAmountTemplate } from "./DriverQuery";
import { ServicePriceUnit } from "./Service";
import { WorkTypeRestrictionMap } from "./ServicePriceBlocks";

export interface ResourcePriceBlock {
    id: string; // uuid
}

export interface CalculatedResourcePriceBlock extends ResourcePriceBlock {
    resource: CalculatedResourcePriceBlockResource;
    amount: number;
    basis: ServicePriceUnit | MachineCounterType | CalculatedResourceDriverQueryBasis;
    workTypeRestriction: WorkTypeRestrictionMap | null;
}

export enum CalculatedResourcePriceBlockResourceType {
    FIXED = "FIXED",
    DRIVER_QUERY = "DRIVER_QUERY",
}

export type CalculatedResourcePriceBlockResource =
    | {
          type: CalculatedResourcePriceBlockResourceType.FIXED;
          resourceId: Resource["id"];
          resourceVariantId: string;
      }
    | {
          type: CalculatedResourcePriceBlockResourceType.DRIVER_QUERY;
          driverQueryTemplateId: DriverQueryResourceWithAmountTemplate["id"];
      };

export interface CalculatedResourceDriverQueryBasis {
    driverQueryTemplate: AnyDriverQueryTemplate["id"];
}

export interface DriverQueryResourcePriceBlock extends ResourcePriceBlock {
    driverQueryTemplate: DriverQueryResourceWithAmountTemplate["id"];
}
