import { TimeTracking } from "@farmact/model/src/model/TimeTracking";
import { getInternalWorkTypeName } from "@farmact/model/src/model/workTypes/InternalWorkType";
import { getOrderWorkTypeName } from "@farmact/model/src/model/workTypes/OrderWorkType";
import { faPencil } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IonButton } from "@ionic/react";
import clsx from "clsx";
import dayjs from "dayjs";
import { ComponentPropsWithoutRef, useMemo, useState } from "react";
import { calculateTimeTrackingAmount } from "../../../../../../../util/calculationUtils";
import { Tooltip } from "../../../../../../Tooltip/Tooltip";
import { EditTimeTrackingModal } from "../../../../../../timeTracking/EditTimeTrackingModal/EditTimeTrackingModal";
import { WorkTimeTag } from "./WorkTimeTag/WorkTimeTag";
import "./tableCells.scss";
import { useCanEditTimeTracking } from "../../../../../../../util/customHooks/useCanEditTimeTracking";

interface ITableCellsProps extends ComponentPropsWithoutRef<"div"> {
    timeTracking: TimeTracking;
    allowEmployeeChange: boolean;
}

export const TableCells = (props: ITableCellsProps) => {
    const { timeTracking, allowEmployeeChange, ...restProps } = props;

    const [showEditModal, setShowEditModal] = useState(false);
    const [timeTrackingEdits, setTimeTrackingEdits] = useState<Partial<TimeTracking>>({});
    const { canEditTimeTracking, hint } = useCanEditTimeTracking(timeTracking);

    const handleTimeTrackingEdit = (updateData: Partial<TimeTracking>) => {
        setTimeTrackingEdits(prev => ({
            ...prev,
            ...updateData,
        }));
    };
    const showTimeTrackingEdit = () => {
        setShowEditModal(true);
        setTimeTrackingEdits({});
    };
    const dismissTimeTrackingEdit = () => {
        setShowEditModal(false);
        setTimeTrackingEdits({});
    };

    const editedTimeTracking = useMemo(() => {
        return new TimeTracking({
            ...props.timeTracking,
            ...timeTrackingEdits,
        });
    }, [props.timeTracking, timeTrackingEdits]);

    const [ref, setRef] = useState<HTMLDivElement | null>(null);

    return (
        <>
            <div {...restProps} className={clsx("table-cells", props.className)}>
                <div className="table-cells__cell table-cells__work-time">
                    <WorkTimeTag>{formatTime(dayjs(timeTracking.startDateTime).toDate())}</WorkTimeTag>
                    <span>-</span>
                    <WorkTimeTag>{formatTime(dayjs(timeTracking.endDateTime).toDate())}</WorkTimeTag>
                </div>
                <div className="table-cells__cell table-cells__break" />
                <div className="table-cells__cell table-cells__total">{formatWorkTime(timeTracking)}</div>
                <div className="table-cells__cell table-cells__activity">{formatActivity(timeTracking)}</div>
                <div className="table-cells__cell table-cells__note">{timeTracking.note}</div>
                <div className="table-cells__cell">
                    <div ref={setRef}>
                        <IonButton
                            size="small"
                            fill="clear"
                            color="primary"
                            disabled={!canEditTimeTracking}
                            onClick={showTimeTrackingEdit}>
                            <FontAwesomeIcon icon={faPencil} />
                        </IonButton>
                    </div>
                    {!canEditTimeTracking && (
                        <Tooltip trigger={ref} placement="bottom">
                            {hint}
                        </Tooltip>
                    )}
                </div>
            </div>

            <EditTimeTrackingModal
                role="edit"
                timeTracking={editedTimeTracking}
                isOpen={showEditModal}
                onDidDismiss={dismissTimeTrackingEdit}
                onFormChange={handleTimeTrackingEdit}
                onDidUpdate={dismissTimeTrackingEdit}
                allowEmployeeInput={allowEmployeeChange}
            />
        </>
    );
};

function formatTime(date: Date): string {
    return dayjs(date).format("HH:mm");
}

function formatWorkTime(timeTracking: TimeTracking): string {
    const totalTime = calculateTimeTrackingAmount([timeTracking]);

    const roundedMinutes = Math.round(totalTime);
    const hours = Math.floor(roundedMinutes / 60);
    const minutes = roundedMinutes % 60;

    return `${hours}:${minutes.toString().padStart(2, "0")} h`;
}

function formatActivity(timeTracking: TimeTracking): string {
    if (timeTracking.orderWorkType) {
        return getOrderWorkTypeName(timeTracking.orderWorkType);
    }

    if (timeTracking.internalWorkType) {
        return `Intern: ${getInternalWorkTypeName(timeTracking.internalWorkType)}`;
    }

    return "Unbekannt";
}
