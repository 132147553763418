import { Customer } from "@farmact/model/src/model/Customer";
import { useState } from "react";
import { getSingleSelectChangeHandler, SingleSelectProps, useSelectItems } from "../../../util/select";
import { ISingleSelectProps, SingleSelect } from "../SingleSelect/SingleSelect";
import {
    createCustomerSelectItem,
    CustomerOrId,
    getCustomerId,
    THRESHOLD_FOR_LOADING_CUSTOMERS,
    useCustomerSingleSelectFilter,
} from "./customerInputsUtils";
import { useCustomersForCustomerSelect } from "./utils/useCustomersForCustomerSelect";
import { useOrganizationContext } from "../../organization/context/OrganizationContext";

interface ISingleCustomerSelectOwnProps extends Omit<ISingleSelectProps, "data" | "value" | "onChange" | "filter"> {
    /**
     * Specify customers that can be selected. By default `customers` from context are used
     */
    customers?: Customer[];

    value: CustomerOrId | null | undefined;
    filter?: (customer: Customer) => boolean;
}

export type ISingleCustomerSelectProps = SingleSelectProps<Customer, ISingleCustomerSelectOwnProps>;

export const SingleCustomerSelect = (props: ISingleCustomerSelectProps) => {
    const { numberOfCustomers } = useOrganizationContext();
    const {
        customers: controlledCustomers,
        itemCreator = createCustomerSelectItem,
        onValueChange,
        onModelChange,
        value,
        placeholder = "Kunde auswählen",
        ...restProps
    } = props;

    const [currentSearch, setCurrentSearch] = useState("");

    const currentlySelectedCustomerId = typeof value === "object" ? value?.id : value;
    const [customers] = useCustomersForCustomerSelect({
        controlledCustomers,
        currentSelection: currentlySelectedCustomerId ? [currentlySelectedCustomerId] : [],
        search: currentSearch,
        filter: props.filter,
    });

    const handleSearchChange = (newSearch: string) => {
        setCurrentSearch(newSearch);
    };

    const items = useSelectItems(customers, itemCreator);
    const selectFilter = useCustomerSingleSelectFilter(customers);

    return (
        <SingleSelect
            {...restProps}
            placeholder={placeholder}
            data={items}
            value={value ? getCustomerId(value) : null}
            onChange={getSingleSelectChangeHandler(customers, {
                onValueChange,
                onModelChange,
            })}
            filter={selectFilter}
            onSearchChange={handleSearchChange}
            hintAtTyping={numberOfCustomers > THRESHOLD_FOR_LOADING_CUSTOMERS}
        />
    );
};
