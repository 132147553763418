import { Order } from "@farmact/model/src/model/Order";
import { TimeTracking } from "@farmact/model/src/model/TimeTracking";
import { notNullish } from "@farmact/model/src/utils/array";
import dayjs from "dayjs";
import { query, where } from "firebase/firestore";
import { useCallback } from "react";
import { Firebase } from "../../../firebase";
import { useCollectionData } from "../../../firebase/dataHooks";
import { useMergedQueryData } from "../../../firebase/utils/useMergedQueryData/useMergedQueryData";
import { useStableIdArray } from "../../../util/customHooks/useStableArray";
import { ValidationController, useValidation } from "../../../util/customHooks/validation/useValidation";
import { normalizeTimeTracking } from "../../../util/timeTrackingUtils";
import { timeTrackingsDoNotOverlap } from "./editTimeTrackingModalUtils";
import { useCanEditTimeTracking } from "../../../util/customHooks/useCanEditTimeTracking";

interface UseTimeTrackingValidationReturn {
    getOverlappingTimeTrackingsWithOrders: () => TimeTrackingWithOrder[];
    validation: ValidationController;
}

export function useTimeTrackingValidation(
    timeTracking: TimeTracking | null,
    enabled: boolean
): UseTimeTrackingValidationReturn {
    const fetchTrackings =
        enabled && timeTracking?.employeeId && timeTracking.startDateTime && timeTracking.endDateTime;
    const [existingTimeTrackings] = useCollectionData(
        fetchTrackings
            ? Firebase.instance().getTimeTrackingsForEmployeeInDateRange(
                timeTracking.employeeId,
                dayjs(timeTracking.startDateTime).startOf("day"),
                dayjs(timeTracking.endDateTime).endOf("day")
            )
            : undefined,
        [fetchTrackings]
    );
    const { canEditTimeTracking } = useCanEditTimeTracking(timeTracking);

    const relevantOrderIds = useStableIdArray(
        Array.from(
            new Set<Order["id"]>(
                existingTimeTrackings.map(tracking => tracking.order?.orderId ?? null).filter(notNullish)
            )
        )
    );
    const queryGenerator = useCallback((orderIds: Order["id"][]) => {
        return query(Firebase.instance().getAllOrders(), where("id", "in", orderIds));
    }, []);
    const [orders] = useMergedQueryData(relevantOrderIds, queryGenerator);

    const timeTrackingToValidate = timeTracking ? normalizeTimeTracking(timeTracking) : null;
    const trackings = existingTimeTrackings
        .filter(tracking => tracking.id !== timeTrackingToValidate?.id)
        .map(normalizeTimeTracking);
    const validation = useValidation(timeTrackingToValidate, timeTracking => {
        if (!timeTracking || !enabled) {
            return;
        }
        if (!canEditTimeTracking) {
            return "timeTracking_too_long_ago";
        }
        if (trackings.some(tracking => !timeTrackingsDoNotOverlap(tracking, timeTracking))) {
            return "intersects_existing_timeTracking";
        }
    });

    const getOverlappingTimeTrackingsWithOrders = (): TimeTrackingWithOrder[] => {
        const timeTrackingsWithOrders: TimeTrackingWithOrder[] = [];
        if (!timeTrackingToValidate) {
            return timeTrackingsWithOrders;
        }
        for (const tracking of trackings) {
            if (timeTrackingsDoNotOverlap(tracking, timeTrackingToValidate)) {
                continue;
            }
            const order = orders.find(order => order.id === tracking.order?.orderId);
            timeTrackingsWithOrders.push({ timeTracking: tracking, order: order ?? null });
        }
        return timeTrackingsWithOrders;
    };

    return { validation, getOverlappingTimeTrackingsWithOrders };
}

export interface TimeTrackingWithOrder {
    timeTracking: TimeTracking;
    order: Order | null;
}
