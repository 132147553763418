import { AgriculturalContractorOrderWorkType } from "./AgriculturalContractorOrderWorkType";
import { CommonOrderWorkType } from "./CommonOrderWorkType";
import { LogisticsOrderWorkType } from "./LogisticsOrderWorkType";

export type OrderWorkType = AgriculturalContractorOrderWorkType | LogisticsOrderWorkType;
export const OrderWorkType = { ...LogisticsOrderWorkType, ...AgriculturalContractorOrderWorkType };

export function getOrderWorkTypeName(workType: OrderWorkType) {
    switch (workType) {
        case CommonOrderWorkType.USE:
            return "Einsatzzeit";
        case CommonOrderWorkType.REPAIR:
            return "Reparieren";
        case CommonOrderWorkType.WAIT:
            return "Warten";
        case CommonOrderWorkType.PAUSE:
            return "Pause";
        case CommonOrderWorkType.RIDE:
            return "Fahrtzeit";
        case CommonOrderWorkType.OTHER:
            return "Sonstiges";
        case AgriculturalContractorOrderWorkType.WASH:
            return "Waschen";
        case AgriculturalContractorOrderWorkType.MOUNT:
            return "Rüsten";
        case LogisticsOrderWorkType.LOAD:
            return "Beladen";
        case LogisticsOrderWorkType.UNLOAD:
            return "Entladen";
        default:
            return workType;
    }
}
