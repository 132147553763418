import { Employee } from "./Employee";

export class Overtime {
    public id: string;
    public employeeId: Employee["id"];
    public date: string;
    /**
     * amount in hours
     */
    public amount: number;
    public note: string;
    constructor(initialValues?: Partial<Overtime>) {
        this.id = initialValues?.id ?? "";
        this.employeeId = initialValues?.employeeId ?? "";
        this.date = initialValues?.date ?? new Date().toISOString();
        this.amount = initialValues?.amount ?? 0;
        this.note = initialValues?.note ?? "";
    }
}
