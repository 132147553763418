import { Absence } from "@farmact/model/src/model/Absence";
import { Overtime } from "@farmact/model/src/model/Overtime";
import { TimeTracking } from "@farmact/model/src/model/TimeTracking";
import { createContext, useContext } from "react";

export interface DayGroupedWorkTimes {
    day: Date;
    timeTrackings: TimeTracking[];
    overtimes: Overtime[];
    absences: Absence[];
}

interface ITableGroupContext {
    open: boolean;
    setOpen: (open: boolean) => void;

    group: DayGroupedWorkTimes;
}
export const TableGroupContext = createContext<ITableGroupContext>({
    open: false,
    setOpen: () => { },
    group: { day: new Date(), timeTrackings: [], overtimes: [], absences: [] },
});
export function useTableGroupContext() {
    return useContext(TableGroupContext);
}
