import { immerable } from "immer";
import { computeDates } from "../utils/computeDates";
import { generateSearchableSubstrings } from "../utils/generateSearchableSubstrings";
import { Bill } from "./Bill";
import { Customer } from "./Customer";
import { DeliveryNote } from "./DeliveryNote";
import { Marker } from "./Marker";
import { OperatingUnit } from "./OperatingUnit";
import { OrderStatus } from "./OrderStatus";
import { ProtocolItem } from "./ProtocolItem";
import { RentalOrder } from "./RentalOrder";
import { Resource, ResourceUnit } from "./Resource";
import { AnyResourcePriceStructure } from "./ResourcePrice";
import { ResourceUsage } from "./ResourceUsage";
import { Track } from "./Track";
import { DriverQueryInputTime, DriverQueryResourceRestriction, DriverQueryType } from "./services/DriverQuery";
import { Service, ServicePriceUnit } from "./services/Service";
import { TaskRecordType } from "./services/TaskRecords";
import { Weighing } from "./Weighing";
import { Employee } from "./Employee";

export class Order {
    [immerable] = true;
    public id: string;
    public orderNumber: number | null;
    public customerIds: Customer["id"][];
    public name: string;
    public employeeId: string | null;
    public appUserId: string | null;
    public status: OrderStatus;
    public serviceId: string | null;
    public statistics: { timeInFields: { [fieldId: string]: number } | null };
    public creatorId: Employee["id"] | null;
    /**
     * Snapshot of the service used for this order.
     * Should be set after the order was first started to ensure analytics / receipt
     * calculations are consistent and don't change in the future.
     */
    public serviceSnapshot: ServiceSnapshot | null;
    /**
     * Planned start date time.
     */
    public plannedStartDateTime: string | null;
    /**
     * When the order was actually started.
     * Automatically set by firestore triggered function `updateTrackedTimesOfOrders`. Do not change manually.
     */
    public readonly trackedStartDateTime: string | null;
    /**
     * Saves the actually displayed startDateTime which is primary the tracked time and has the planned time as fallback
     * Automatically set by firestore triggered function.
     */
    public readonly displayedStartDateTime: string | null;
    /**
     * Planned end date time.
     */
    public plannedEndDateTime: string | null;
    /**
     * When the order was actually ended.
     * Automatically set by firestore triggered function `updateTrackedTimesOfOrders`. Do not change manually.
     */
    public readonly trackedEndDateTime: string | null;
    /**
     * Saves the actually displayed endDateTime which is primary the tracked time and has the planned time as fallback
     * Automatically set by firestore triggered function.
     */
    public readonly displayedEndDateTime: string | null;
    /**
     * Only used for querying. Automatically set by firestore triggered function.
     */
    public readonly plannedDates: string[];
    /**
     * Only used for querying. Automatically set by firestore triggered function.
     */
    public readonly displayedDates: string[];
    public machineIds: string[];
    public fieldIds: string[];
    public loadingPointIds: string[];
    public unloadingPointIds: string[];
    public markers: Marker[];
    public trackIds: Array<Track["id"]>;

    public mapStructuresSequence: MapStructuresSequence;
    public isMapStructuresSequenceEnabled: boolean;

    public resourceUsages: ResourceUsage[];
    public other: string;
    public archived: boolean;
    /**
     * Date time intervals when order was active (started and not yet stopped).
     */
    public activeTime: ActiveTime[];
    /**
     * Wether or not the order is locked for service query changes. This does only change once from FALSE -> TRUE.
     * Basically this could be calculated by checking if `activeTime` is empty. Unfortunately Firestore does not support such a query.
     */
    public hasBeenStarted: boolean;
    public protocol: ProtocolItem[];
    public weighings: Weighing[];
    public geoLocationTrackingIds: string[];
    public notificationSeen?: boolean;
    /**
     * Firebase Storage paths to images associated to this order
     */
    public photos: string[];

    public driverQueriesYesNo: DriverQueryYesNo[];
    public driverQueriesSingleValue: DriverQuerySingleValue[];
    public driverQueriesBeforeAfter: DriverQueryBeforeAfter[];
    public driverQueriesResourceWithAmount: DriverQueryResourceWithAmount[];
    public driverQueriesResourceOnly: DriverQueryResourceOnly[];
    public taskRecords: TaskRecord[];

    /**
     * Resources used in calculated price blocks
     */
    public resourceSnapshots: ResourceSnapshot[];

    public operatingUnitId: OperatingUnit["id"] | null;

    public bills: OrderBills;
    public deliveryNotes: OrderDeliveryNotes;
    public billingDisabled: OrderBillingDisabled;
    /**
     * only for use in queries, set via triggers
     */
    public _searchableSubstrings: string[];

    public decoupleTimeTrackings: boolean;

    public projectId: string | null;

    constructor(initialValues?: Partial<Order> & { trackedResourceUsages?: ResourceUsage[] }) {
        this.id = initialValues?.id ?? "";
        this.orderNumber = initialValues?.orderNumber ?? null;
        this.customerIds = initialValues?.customerIds ?? [];
        this.name = initialValues?.name ?? "";
        this.employeeId = initialValues?.employeeId || null;
        this.appUserId = initialValues?.appUserId || null;
        this.serviceId = initialValues?.serviceId || null;
        this.statistics = { timeInFields: initialValues?.statistics?.timeInFields || null };
        this.creatorId = initialValues?.creatorId || null;
        this.serviceSnapshot = initialValues?.serviceSnapshot ?? null;
        this.status = initialValues?.status ?? OrderStatus.ASSIGNED;
        this.plannedStartDateTime = initialValues?.plannedStartDateTime ?? null;
        this.trackedStartDateTime = initialValues?.trackedStartDateTime ?? null;
        this.displayedStartDateTime =
            initialValues?.displayedStartDateTime ??
            initialValues?.trackedStartDateTime ??
            initialValues?.plannedStartDateTime ??
            null;
        this.plannedEndDateTime = initialValues?.plannedEndDateTime ?? null;
        this.trackedEndDateTime = initialValues?.trackedEndDateTime ?? null;
        this.displayedEndDateTime =
            initialValues?.displayedEndDateTime ??
            initialValues?.trackedEndDateTime ??
            initialValues?.plannedEndDateTime ??
            null;
        this.plannedDates =
            initialValues?.plannedDates ??
            computeDates(this?.plannedStartDateTime ?? null, this?.plannedEndDateTime ?? null);
        this.displayedDates =
            initialValues?.displayedDates ??
            computeDates(this?.displayedStartDateTime ?? null, this?.displayedEndDateTime ?? null);
        this.machineIds = initialValues?.machineIds ?? [];
        this.fieldIds = initialValues?.fieldIds ?? [];
        this.loadingPointIds = initialValues?.loadingPointIds ?? [];
        this.unloadingPointIds = initialValues?.unloadingPointIds ?? [];
        this.markers = initialValues?.markers ?? [];
        this.trackIds = initialValues?.trackIds ?? [];
        this.mapStructuresSequence = initialValues?.mapStructuresSequence ?? [
            ...this.fieldIds,
            ...this.markers.map(marker => marker.id),
            ...this.trackIds,
            ...this.loadingPointIds,
            ...this.unloadingPointIds,
        ];
        this.isMapStructuresSequenceEnabled = initialValues?.isMapStructuresSequenceEnabled ?? false;
        // trackedResourceUsages is the legacy value
        this.resourceUsages = initialValues?.resourceUsages ?? initialValues?.trackedResourceUsages ?? [];
        this.other = initialValues?.other ?? "";
        this.archived = initialValues?.archived ?? false;
        this.activeTime = initialValues?.activeTime ?? [];
        this.hasBeenStarted = initialValues?.hasBeenStarted ?? false;
        this.protocol = initialValues?.protocol ?? [];
        this.weighings = initialValues?.weighings ?? [];
        /**
         * set through firebase functions (firestore triggers) only
         */
        this.geoLocationTrackingIds = initialValues?.geoLocationTrackingIds ?? [];
        this.notificationSeen = initialValues?.notificationSeen;
        this.photos = initialValues?.photos ?? [];
        this.driverQueriesYesNo = initialValues?.driverQueriesYesNo ?? [];
        this.driverQueriesSingleValue = initialValues?.driverQueriesSingleValue ?? [];
        this.driverQueriesBeforeAfter = initialValues?.driverQueriesBeforeAfter ?? [];
        this.driverQueriesResourceWithAmount = initialValues?.driverQueriesResourceWithAmount ?? [];
        this.driverQueriesResourceOnly = initialValues?.driverQueriesResourceOnly ?? [];
        this.taskRecords = initialValues?.taskRecords ?? [];
        this.resourceSnapshots = initialValues?.resourceSnapshots ?? [];
        this.operatingUnitId = initialValues?.operatingUnitId ?? null;
        this.bills = initialValues?.bills ?? {};
        this.deliveryNotes = initialValues?.deliveryNotes ?? {};
        this.billingDisabled = initialValues?.billingDisabled ?? {};
        this.decoupleTimeTrackings = initialValues?.decoupleTimeTrackings ?? false;
        this.projectId = initialValues?.projectId ?? null;
        this._searchableSubstrings =
            (initialValues as { _searchableSubstrings?: string[] })?._searchableSubstrings ?? [];
    }
}

export type ActiveTime = {
    id: string;
    // ISO time
    start: string;
    end: string | null;
    customerId: Customer["id"] | null;
};

export class ServiceSnapshot extends Service {
    public snapshotTimestamp: string;

    constructor(initialData?: Partial<ServiceSnapshot>) {
        super(initialData);
        this.snapshotTimestamp = initialData?.snapshotTimestamp ?? new Date().toISOString();
    }
}

export class ResourceSnapshot extends Resource {
    public snapshotTimestamp: string;

    constructor(initialData?: Partial<ResourceSnapshot>) {
        super(initialData);
        this.snapshotTimestamp = initialData?.snapshotTimestamp ?? new Date().toISOString();
    }
}

export interface IdentifiablePhoto {
    storagePath: string;
    imageSrc: string;
}

export interface TaskRecord {
    id: string;
    timeStamp: string;
    record: number | null;
    type: TaskRecordType;
    unit: ServicePriceUnit;
    customerId: Customer["id"] | null;
}

export interface DriverQuery {
    id: string;
    type: DriverQueryType;
    /**
     * Short descriptive name of the driver query
     */
    name: string;
    required: boolean;
    /**
     * Additional information for the driver (e.g. description where to find values to input)
     */
    info: string | null;
}

export interface DriverQueryYesNo extends DriverQuery {
    type: DriverQueryType.YES_NO;
    when: DriverQueryInputTime;
    value: DriverQueryYesNoValue;
}

export interface DriverQueryYesNoValue {
    checked: boolean | null;
    timestamp: string | null;
    customerId: Customer["id"] | null;
    history: DriverQueryYesNoHistoryEntry[];
}

export interface DriverQueryYesNoHistoryEntry {
    id: string;
    timestamp: string | null;
    customerId: Customer["id"] | null;
    checked: boolean;
}

export interface DriverQuerySingleValue extends DriverQuery {
    type: DriverQueryType.VALUE;
    when: DriverQueryInputTime;
    unit: string | null;
    value: DriverQuerySingleValueValue;
}

export interface DriverQuerySingleValueValue {
    value: number | null;
    timestamp: string | null;
    customerId: Customer["id"] | null;
    history: DriverQuerySingleValueHistoryEntry[];
}

export interface DriverQuerySingleValueHistoryEntry {
    id: string;
    timestamp: string | null;
    customerId: Customer["id"] | null;
    value: number;
}

export interface DriverQueryBeforeAfter extends DriverQuery {
    type: DriverQueryType.BEFORE_AFTER;
    unit: string | null;
    value: DriverQueryBeforeAfterValue;
}

export interface DriverQueryBeforeAfterValue {
    before: number | null;
    after: number | null;
    timestamp: string | null;
    customerId: Customer["id"] | null;
    history: DriverQueryBeforeAfterHistoryEntry[];
}

export interface DriverQueryBeforeAfterHistoryEntry {
    id: string;
    timestamp: string | null;
    customerId: Customer["id"] | null;
    before: number;
    after: number;
}

export interface DriverQueryResourceWithAmount extends DriverQuery {
    type: DriverQueryType.RESOURCE_WITH_AMOUNT;
    when: DriverQueryInputTime;
    restriction: DriverQueryResourceRestriction | null;
    value: DriverQueryResourceWithAmountValue;
}

export interface DriverQueryResourceWithAmountValue {
    customerId: Customer["id"] | null;
    usage: ResourceUsage | null;
    history: DriverQueryResourceWithAmountHistoryEntry[];
}

export interface DriverQueryResourceWithAmountHistoryEntry {
    id: string;
    customerId: Customer["id"] | null;
    usage: ResourceUsage;
}

export interface DriverQueryResourceOnly extends DriverQuery {
    type: DriverQueryType.RESOURCE_ONLY;
    when: DriverQueryInputTime;
    restriction: DriverQueryResourceRestriction | null;
    value: DriverQueryResourceOnlyValue;
}

export interface DriverQueryResourceOnlyResource {
    id: string;
    variantId: string;
    name: string;
    costsPerUnit: number;
    pricePerUnit: AnyResourcePriceStructure;
    unit: ResourceUnit;
    vatPercentPoints: number | null;
    vskz_mr: string | null;
}

export interface DriverQueryResourceOnlyValue {
    customerId: Customer["id"] | null;
    resource: DriverQueryResourceOnlyResource | null;
    timestamp: string | null; // ISO-Datetime
    history: DriverQueryResourceOnlyHistoryEntry[];
}

export interface DriverQueryResourceOnlyHistoryEntry {
    id: string;
    customerId: Customer["id"] | null;
    resource: DriverQueryResourceOnlyResource | null;
    timestamp: string; // ISO-Datetime
}

export type AnyDriverQuery =
    | DriverQueryYesNo
    | DriverQuerySingleValue
    | DriverQueryBeforeAfter
    | DriverQueryResourceWithAmount
    | DriverQueryResourceOnly;
export type AnyDriverQueryHistory =
    | DriverQueryYesNoHistoryEntry
    | DriverQuerySingleValueHistoryEntry
    | DriverQueryBeforeAfterHistoryEntry
    | DriverQueryResourceWithAmountHistoryEntry
    | DriverQueryResourceOnlyHistoryEntry;

/**
 * List of mapstructure IDs (field, track, marker). Index within array determines order.
 */
export type MapStructuresSequence = string[];

export function preparePartialOrderForFirestore(partialOrder: Partial<Order>): Partial<Order> {
    const preparedForFirestore: Partial<Order> = { ...partialOrder };

    /**
     * We have to check if the passed value is actually an Array.
     * `partialOrder.resourceUsages` can also be an instanceof `firebase.firestore.FieldValue`
     * when used together with `firebase.firestore.FieldValue.arrayUnion(...)`.
     */
    if (partialOrder.resourceUsages && Array.isArray(partialOrder.resourceUsages)) {
        preparedForFirestore.resourceUsages = partialOrder.resourceUsages.map(resourceUsage => ({ ...resourceUsage }));
    }

    if (partialOrder.driverQueriesResourceWithAmount) {
        preparedForFirestore.driverQueriesResourceWithAmount = partialOrder.driverQueriesResourceWithAmount.map(
            driverQuery => ({
                ...driverQuery,
                value: {
                    ...driverQuery.value,
                    usage: driverQuery.value.usage ? { ...driverQuery.value.usage } : null,
                    history: driverQuery.value.history.map(entry => ({ ...entry, usage: { ...entry.usage } })),
                },
            })
        );
    }

    if (partialOrder.serviceSnapshot) {
        preparedForFirestore.serviceSnapshot = {
            ...partialOrder.serviceSnapshot,
        };
    }

    if (partialOrder.markers && Array.isArray(partialOrder.markers)) {
        preparedForFirestore.markers = partialOrder.markers.map(marker => ({ ...marker }));
    }

    if (partialOrder.resourceSnapshots && Array.isArray(partialOrder.resourceSnapshots)) {
        preparedForFirestore.resourceSnapshots = partialOrder.resourceSnapshots.map(resource => ({
            ...resource,
        }));
    }

    // handled by trigger functions
    delete preparedForFirestore.bills;
    delete preparedForFirestore.deliveryNotes;

    return preparedForFirestore;
}

export function isOrder(order: Order | RentalOrder): order is Order {
    const cast = order as Order;
    return cast.hasBeenStarted !== undefined && cast.activeTime !== undefined;
}

export type OrderWithoutOrderNumber = Omit<Order, "orderNumber"> & { orderNumber?: never };

export type OrderBills = Partial<{
    [customerId: Customer["id"]]: Bill["id"] | null;
}>;

export type OrderDeliveryNotes = Partial<{
    [customerId: Customer["id"]]: DeliveryNote["id"] | null;
}>;

export type OrderBillingDisabled = Partial<{
    /**
     * `true` means billing is disabled
     * `false | null | undefined` means billing is enabled
     */
    [customerId: Customer["id"]]: boolean;
}>;

export function generateSearchableSubstringsForOrder(order: Partial<Order>) {
    const searchableCustomerAttributes: string[] = [order.name, order.orderNumber?.toString()].filter(
        Boolean
    ) as string[];

    return generateSearchableSubstrings(searchableCustomerAttributes);
}

export enum WeighingMode {
    UNKNOWN = "UNKNOWN",
    TARA_FIRST = "TARA_FIRST",
    LOAD_FIRST = "LOAD_FIRST",
}
