import { Absence } from "@farmact/model/src/model/Absence";
import { computeDates } from "@farmact/model/src/utils/computeDates";
import dayjs, { Dayjs } from "dayjs";

export function clipAbsences(absences: Absence[], startDate: Dayjs, endDate: Dayjs): Absence[] {
    return absences.map(absence => clipAbsence(absence, startDate, endDate));
}

export function clipAbsence(absence: Absence, startDate: Dayjs, endDate: Dayjs): Absence {
    const clippedAbsence = { ...absence };
    if (dayjs(absence.startDate).isBefore(startDate)) {
        clippedAbsence.startDate = startDate.startOf("date").toISOString();
    }
    if (dayjs(absence.endDate).isAfter(endDate)) {
        clippedAbsence.endDate = endDate.endOf("date").toISOString();
    }
    clippedAbsence.dates = computeDates(clippedAbsence.startDate, clippedAbsence.endDate);
    return clippedAbsence;
}
