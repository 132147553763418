import { IconDefinition, faInfoCircle, faPlane } from "@fortawesome/free-solid-svg-icons";
import dayjs from "dayjs";
import { Employee } from "./Employee";

export class Absence {
    id: string;
    employeeId: Employee["id"];
    type: AbsenceType;
    /**
   * Date Isostring
   */
    startDate: string;
    /**
   * Date Isostring
   */
    endDate: string;
    /**
   * used for querying
   */
    dates: string[];
    note: string;
    constructor(initialValues?: Partial<Absence>) {
        this.id = initialValues?.id ?? "";
        this.employeeId = initialValues?.employeeId ?? "";
        this.type = initialValues?.type ?? AbsenceType.VACATION;
        this.startDate = initialValues?.startDate ?? dayjs().add(1, "day").toISOString();
        this.endDate = initialValues?.endDate ?? dayjs().add(2, "day").toISOString();
        this.dates = initialValues?.dates ?? [];
        this.note = initialValues?.note ?? "";
    }
}

export enum AbsenceType {
  VACATION = "VACATION",
}
export function getValueForAbsenceType(absenceType: AbsenceType) {
    switch (absenceType) {
        case AbsenceType.VACATION:
            return "Urlaub";
        default:
            return "Abwesenheit";
    }
}

export function getAbsenceIcon(absenceType: AbsenceType): IconDefinition {
    switch (absenceType) {
        case AbsenceType.VACATION:
            return faPlane;
        default:
            return faInfoCircle;
    }
}
