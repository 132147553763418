import { Variant } from "@farmact/model/src/model/CompanyMeta";
import { Customer } from "@farmact/model/src/model/Customer";
import { Employee } from "@farmact/model/src/model/Employee";
import { Order } from "@farmact/model/src/model/Order";
import { TimeTracking, TimeTrackingMapStructureType, TimeTrackingOrder } from "@farmact/model/src/model/TimeTracking";
import { AgriculturalContractorInternalWorkType } from "@farmact/model/src/model/workTypes/AgriculturalContractorInternalWorkType";
import { AgriculturalContractorOrderWorkType } from "@farmact/model/src/model/workTypes/AgriculturalContractorOrderWorkType";
import { getInternalWorkTypeName, InternalWorkType } from "@farmact/model/src/model/workTypes/InternalWorkType";
import { LogisticsInternalWorkType } from "@farmact/model/src/model/workTypes/LogisticsInternalWorkType";
import { LogisticsOrderWorkType } from "@farmact/model/src/model/workTypes/LogisticsOrderWorkType";
import { getOrderWorkTypeName, OrderWorkType } from "@farmact/model/src/model/workTypes/OrderWorkType";
import { IonButton, IonButtons, IonContent, IonFooter, IonSegment, IonSegmentButton, IonToolbar } from "@ionic/react";
import dayjs from "dayjs";
import { DocumentReference } from "firebase/firestore";
import React, { useState } from "react";
import { Firebase } from "../../../firebase";
import { useCollectionData, useDocumentData } from "../../../firebase/dataHooks";
import { useFields } from "../../../util/customHooks/fields/useFields";
import { useOrderCustomers } from "../../../util/customHooks/order/useOrderCustomers";
import { useFormValidation } from "../../../util/customHooks/useFormValidation";
import { useValidation } from "../../../util/customHooks/validation/useValidation";
import { computed } from "../../../util/functions";
import { recordError } from "../../../util/recordError";
import { normalizeTimeTracking } from "../../../util/timeTrackingUtils";
import * as Item from "../../FaItem";
import { FaItemGroup } from "../../FaItemGroup/FaItemGroup";
import { FarmActModal } from "../../FarmActModal/FarmActModal";
import { useToastContext } from "../../ToastContext";
import { useAppCompanyContext } from "../../authentication/AppCompanyContext";
import { Hint } from "../../hint/Hint";
import { DateTimeInput } from "../../inputs/DateTimeInput/DateTimeInput";
import { Input } from "../../inputs/Input/Input";
import { SingleSelect } from "../../inputs/SingleSelect/SingleSelect";
import { SubmitFormOnEnter } from "../../inputs/SubmitFormOnEnter";
import { SingleCustomerSelect } from "../../inputs/customerInputs/SingleCustomerSelect";
import { SingleEmployeeSelect } from "../../inputs/employeeInputs/SingleEmployeeSelect";
import { useOrderContext } from "../../orders/OrderContext/OrderContext";
import { StandardModalHeader } from "../../structure/StandardModalHeader/StandardModalHeader";
import "./editTimeTrackingModal.scss";
import { getErrorMessages } from "./editTimeTrackingModalUtils";
import { useTimeTrackingValidation } from "./useTimeTrackingValidation";
import { useCanEditTimeTracking } from "../../../util/customHooks/useCanEditTimeTracking";
import { TimeTrackingRestrictionType } from "@farmact/model/src/model/AppCompany";
import { useRole } from "../../../util/customHooks/useRole";
import { Role } from "@farmact/model/src/model/Role";

interface IEditTimeTrackingModalProps {
    timeTracking: TimeTracking | null;
    isOpen: boolean;
    onFormChange: (updatedValues: Partial<TimeTracking>) => void;
    onDidDismiss: () => void;
    onDidUpdate?: () => void;
    role: "add" | "edit";
    /**
     * Whether to disable order selection (use to predefine order and do not allow changes)
     * @default false
     */
    isWorkKindLocked?: boolean;
    /**
     * Whether the employee can be changed.
     */
    allowEmployeeInput: boolean;
}

export const EditTimeTrackingModal = (props: IEditTimeTrackingModalProps) => {
    const { order: contextOrder } = useOrderContext();
    const { onMessage: showMessage } = useToastContext();
    const { companyMeta, appCompany } = useAppCompanyContext();
    const { role } = useRole();

    const [workKind, setWorkKind] = useState(getWorkKind(props.timeTracking));
    const [previousIsOpen, setPreviousIsOpen] = useState(false);
    const { hint: timeTrackingEditHint } = useCanEditTimeTracking(props.timeTracking);

    const timeTrackingEmployeeId = props.timeTracking?.employeeId;
    const [userOrders] = useCollectionData(
        !props.isWorkKindLocked && timeTrackingEmployeeId
            ? Firebase.instance().getAllOrdersForEmployee(timeTrackingEmployeeId, 60)
            : undefined,
        [timeTrackingEmployeeId, props.isWorkKindLocked]
    );
    const [currentOrder] = useDocumentData(getOrderQuery(props.timeTracking), [props.timeTracking?.order?.orderId]);
    const isRestrictedVersion = role ? [Role.EMPLOYEE, Role.TEMPORARY_WORKER, Role.PLANNER].includes(role) : true;

    const { fields } = useFields(currentOrder?.fieldIds ?? []);

    if (!previousIsOpen && props.isOpen) {
        setPreviousIsOpen(props.isOpen);
        setWorkKind(getWorkKind(props.timeTracking));
    }

    if (previousIsOpen && !props.isOpen) {
        setPreviousIsOpen(props.isOpen);
        setWorkKind("internal");
    }

    // if used outside an order context, order.decoupleTimeTrackings is false as per the default constructor
    const timeTrackingIsDecoupledOrderTimeTracking = contextOrder.decoupleTimeTrackings;

    const { validation: timeTrackingValidation, getOverlappingTimeTrackingsWithOrders } = useTimeTrackingValidation(
        props.timeTracking,
        !timeTrackingIsDecoupledOrderTimeTracking
    );

    const workKindValidation = useFormValidation({
        customerOrder: useValidation(props.timeTracking?.order, timeTrackingOrder => {
            if (workKind !== "customer") {
                return;
            }

            if (!timeTrackingOrder) {
                return "invalid_customer_order";
            }
            if (!timeTrackingOrder?.orderId) {
                return "missing_order";
            }
            if (!timeTrackingOrder?.customerId && currentOrder && currentOrder.customerIds.length > 0) {
                return "missing_customer";
            }
        }),
        customerWork: useValidation(props.timeTracking?.orderWorkType, orderWorkType => {
            if (workKind === "customer" && !orderWorkType) {
                return "invalid_customer_workkind";
            }
        }),
        internal: useValidation(props.timeTracking?.internalWorkType, internalWorkType => {
            if (workKind === "internal" && !internalWorkType) {
                return "invalid_internal_timetracking";
            }
        }),
    });

    const handleDismiss = () => {
        timeTrackingValidation.reset();
        workKindValidation.resetForm();
        props.onDidDismiss();
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!props.timeTracking || !timeTrackingEmployeeId) {
            recordError("Error when adding/editing time tracking.", {
                timeTracking: props.timeTracking,
            });
            showMessage("Konnte Mitarbeiterdaten nicht finden. Bitte versuche es später erneut.", "danger");
            return;
        }
        if (!timeTrackingValidation.validate()) {
            if (timeTrackingValidation.error === "intersects_existing_timeTracking") {
                showMessage("Die Zeiten überschneiden sich mit anderen Zeiterfassungen", "warning");
                return;
            }
            if (timeTrackingValidation.error === "timeTracking_too_long_ago") {
                showMessage(timeTrackingEditHint, "warning");
                return;
            }
        }

        if (!workKindValidation.checkIsFormValid()) {
            showMessage("Bitte fülle zuerst die Tätigkeit bzw. den Auftrag aus.", "warning");
            return;
        }

        let timeTrackingToSubmit: TimeTracking = normalizeTimeTracking(props.timeTracking);

        /**
         * Reset other fields
         */
        if (workKind === "customer") {
            timeTrackingToSubmit = { ...timeTrackingToSubmit, internalWorkType: null };
        } else {
            timeTrackingToSubmit = { ...timeTrackingToSubmit, order: null, orderWorkType: null };
        }

        try {
            // if used outside an order context, order.decoupleTimeTrackings is false as per the default constructor
            const forOrderWithDecoupledTimeTrackings = contextOrder.decoupleTimeTrackings ? contextOrder.id : null;
            if (timeTrackingToSubmit.id) {
                Firebase.instance().updateWholeTimeTracking(timeTrackingToSubmit, forOrderWithDecoupledTimeTrackings);
            } else {
                await Firebase.instance().createTimeTracking(timeTrackingToSubmit, forOrderWithDecoupledTimeTrackings);
            }

            props.onDidUpdate?.();
            handleDismiss();
        } catch (error: any) {
            if (error.message === "invalid-datetimes") {
                showMessage("Startzeit muss vor Endzeit liegen!", "warning");
            } else {
                showMessage(`Unbekannter Fehler: ${error}`, "danger");
            }
        }
    };

    const handleTimeTrackingDelete = () => {
        if (!props.timeTracking) {
            return;
        }

        Firebase.instance().deleteTimeTracking(
            props.timeTracking.id,
            contextOrder.decoupleTimeTrackings ? contextOrder.id : null
        );
        handleDismiss();
    };

    const relevantOrders = [...userOrders];
    if (currentOrder && !userOrders.map(order => order.id).includes(currentOrder.id)) {
        relevantOrders.push(currentOrder);
    }

    const orderOptions = relevantOrders
        .map(order => ({
            label: `${order.orderNumber} - ${order.name}`,
            value: order.id,
        }))
        .sort((orderOption1, orderOption2) => orderOption1.label.localeCompare(orderOption2.label));

    const availableInternalWorkTypeOptions = Object.values(
        companyMeta?.variant === Variant.AGRICULTURAL_CONTRACTOR
            ? AgriculturalContractorInternalWorkType
            : LogisticsInternalWorkType
    ).map(workType => ({
        value: workType,
        label: getInternalWorkTypeName(workType),
    }));

    const availableOrderWorkTypeOptions = Object.values(
        companyMeta?.variant === Variant.AGRICULTURAL_CONTRACTOR
            ? AgriculturalContractorOrderWorkType
            : LogisticsOrderWorkType
    ).map(workType => ({
        value: workType,
        label: getOrderWorkTypeName(workType),
    }));

    const { customers: selectableCustomers } = useOrderCustomers(currentOrder);

    const handleCustomerChange = (customerId: Customer["id"] | null) => {
        if (!props.timeTracking?.order) {
            recordError("Could not change customer when editing time tracking", {
                timeTracking: props.timeTracking,
                orderData: props.timeTracking?.order,
            });
            return;
        }
        props.onFormChange({
            order: {
                ...props.timeTracking.order,
                customerId,
                mapStructure: null,
            },
        });
    };

    const handleOrderChange = (orderId: Order["id"] | null) => {
        if (!orderId) {
            return;
        }

        const timeTrackingOrder = props.timeTracking?.order;
        const currentSelectedCustomerId = timeTrackingOrder?.customerId;
        const currentSelectedMapStructure = timeTrackingOrder?.mapStructure;

        const updateObject: TimeTrackingOrder = {
            orderId,
            customerId: currentSelectedCustomerId ?? null,
            mapStructure: timeTrackingOrder?.mapStructure ?? null,
        };

        const order = relevantOrders.find(order => order.id === orderId);
        if (!order) {
            return;
        }

        if (order.customerIds.length === 1) {
            updateObject.customerId = order.customerIds[0];
        } else if (currentSelectedCustomerId && !order.customerIds.includes(currentSelectedCustomerId)) {
            updateObject.customerId = null;
            updateObject.mapStructure = null;
        }

        if (currentSelectedMapStructure) {
            if (
                currentSelectedMapStructure.type === TimeTrackingMapStructureType.FIELD &&
                !order.fieldIds.includes(currentSelectedMapStructure.fieldId)
            ) {
                updateObject.mapStructure = null;
            }
        }

        props.onFormChange({
            order: updateObject,
        });
    };

    const handleFieldChange = (fieldId: string | null) => {
        const timeTrackingOrder = props.timeTracking?.order;
        if (!timeTrackingOrder) {
            return;
        }

        if (!fieldId) {
            props.onFormChange({
                order: {
                    ...timeTrackingOrder,
                    mapStructure: null,
                },
            });
            return;
        }

        const field = fields.find(field => {
            return field.id === fieldId;
        });
        if (!field) {
            return;
        }

        const timeTrackingOrderUpdateData: Partial<TimeTrackingOrder> = {};

        if (field.customerId !== timeTrackingOrder.customerId) {
            timeTrackingOrderUpdateData.customerId = field.customerId;
        }

        props.onFormChange({
            order: {
                ...timeTrackingOrder,
                ...timeTrackingOrderUpdateData,
                mapStructure: {
                    type: TimeTrackingMapStructureType.FIELD,
                    fieldId,
                },
            },
        });
    };

    const handleEmployeeChange = (newEmployee: Employee | null) => {
        if (!newEmployee) {
            return;
        }
        props.onFormChange({
            employeeId: newEmployee.id,
            appUserId: newEmployee.appUserId ?? "",
        });
    };

    const selectedOrder = relevantOrders.find(order => {
        return order.id === props.timeTracking?.order?.orderId;
    });

    const selectedFieldId = computed((): string | null => {
        const mapStructure = props.timeTracking?.order?.mapStructure;
        if (!mapStructure) {
            return null;
        }

        if (mapStructure.type !== TimeTrackingMapStructureType.FIELD) {
            return null;
        }

        return mapStructure.fieldId;
    });

    const timeTrackingDaysRestriction =
        appCompany?.settings.employeeSettings.timeTrackingEditRestriction.type === TimeTrackingRestrictionType.DAYS
            ? appCompany?.settings.employeeSettings.timeTrackingEditRestriction.days
            : undefined;
    const hasRestrictedDateInput = timeTrackingDaysRestriction && isRestrictedVersion;
    const minAvailableDateTime = hasRestrictedDateInput
        ? dayjs().subtract(timeTrackingDaysRestriction, "day").toDate()
        : undefined;
    const maxAvailableDateTime = hasRestrictedDateInput
        ? dayjs().add(timeTrackingDaysRestriction, "day").toDate()
        : undefined;
    return (
        <FarmActModal isOpen={props.isOpen} onDidDismiss={handleDismiss} className="edit-time-tracking-modal">
            <div className="default-modal-container">
                <StandardModalHeader
                    title={`Zeiterfassung ${props.role === "add" ? "hinzufügen" : "bearbeiten"}`}
                    onCloseClick={handleDismiss}
                />
                <IonContent>
                    <form onSubmit={handleSubmit}>
                        <SubmitFormOnEnter />
                        <FaItemGroup>
                            <Item.Root error={timeTrackingValidation.error}>
                                <Item.Label>Start</Item.Label>
                                <Item.Content>
                                    <DateTimeInput
                                        min={minAvailableDateTime}
                                        max={maxAvailableDateTime}
                                        value={
                                            props.timeTracking?.startDateTime
                                                ? dayjs(props.timeTracking?.startDateTime)
                                                : null
                                        }
                                        onValueChange={startDateTime => {
                                            props.onFormChange({ startDateTime: startDateTime.toISOString() });
                                        }}
                                    />
                                </Item.Content>
                            </Item.Root>
                            <Item.Root
                                error={timeTrackingValidation.error}
                                className="edit-time-tracking-modal__end-item">
                                <Item.Label>Ende</Item.Label>
                                <Item.Content>
                                    <DateTimeInput
                                        min={minAvailableDateTime}
                                        max={maxAvailableDateTime}
                                        value={
                                            props.timeTracking?.endDateTime
                                                ? dayjs(props.timeTracking?.endDateTime)
                                                : null
                                        }
                                        onValueChange={endDateTime => {
                                            props.onFormChange({ endDateTime: endDateTime.toISOString() });
                                        }}
                                    />
                                </Item.Content>
                            </Item.Root>
                        </FaItemGroup>
                        {timeTrackingValidation.error === "intersects_existing_timeTracking" && (
                            <Hint color="danger" severity="error" className="edit-time-tracking-modal__hint">
                                Die Zeiten überschneiden sich mit anderen Zeiterfassungen:
                                <ul>
                                    {getErrorMessages(getOverlappingTimeTrackingsWithOrders(), props.timeTracking).map(
                                        (message, index) => (
                                            <li key={index}>{message}</li>
                                        )
                                    )}
                                </ul>
                            </Hint>
                        )}
                        {timeTrackingValidation.error === "timeTracking_too_long_ago" && (
                            <>
                                <Hint color="danger" severity="error" className="edit-time-tracking-modal__hint">
                                    {timeTrackingEditHint}
                                </Hint>
                            </>
                        )}
                        <FaItemGroup>
                            {props.allowEmployeeInput && (
                                <Item.Root>
                                    <Item.Label>Mitarbeiter</Item.Label>
                                    <Item.Content>
                                        <SingleEmployeeSelect
                                            value={timeTrackingEmployeeId}
                                            onModelChange={handleEmployeeChange}
                                        />
                                    </Item.Content>
                                </Item.Root>
                            )}
                            {!props.isWorkKindLocked && (
                                <>
                                    <Item.Root>
                                        <Item.Label>Arbeitstyp</Item.Label>
                                        <Item.Content>
                                            <IonSegment
                                                color="secondary"
                                                value={workKind}
                                                onIonChange={event => {
                                                    setWorkKind(event.detail.value! as WorkKind);
                                                }}>
                                                <IonSegmentButton value="customer">Kunde</IonSegmentButton>
                                                <IonSegmentButton value="internal">Intern</IonSegmentButton>
                                            </IonSegment>
                                        </Item.Content>
                                    </Item.Root>

                                    {workKind === "customer" && (
                                        <Item.Root error={workKindValidation.fields.customerOrder.error}>
                                            <Item.Label>Auftrag</Item.Label>
                                            <Item.Content>
                                                <SingleSelect
                                                    placeholder="Auftrag auswählen"
                                                    data={orderOptions}
                                                    value={props.timeTracking?.order?.orderId ?? null}
                                                    onChange={handleOrderChange}
                                                    dropdownPosition="top"
                                                />
                                            </Item.Content>
                                        </Item.Root>
                                    )}
                                </>
                            )}

                            {workKind === "internal" && (
                                <Item.Root error={workKindValidation.fields.internal.error}>
                                    <Item.Label>Interne Arbeit</Item.Label>
                                    <Item.Content>
                                        <SingleSelect
                                            placeholder="Interne Arbeit"
                                            data={availableInternalWorkTypeOptions}
                                            value={props.timeTracking?.internalWorkType ?? undefined}
                                            onChange={newValue =>
                                                props.onFormChange({
                                                    internalWorkType: newValue as InternalWorkType,
                                                })
                                            }
                                        />
                                    </Item.Content>
                                </Item.Root>
                            )}
                            {workKind === "customer" && (
                                <>
                                    <Item.Root error={workKindValidation.fields.customerWork.error}>
                                        <Item.Label>Tätigkeit</Item.Label>
                                        <Item.Content>
                                            <SingleSelect
                                                placeholder="Tätigkeit"
                                                data={availableOrderWorkTypeOptions}
                                                value={props.timeTracking?.orderWorkType ?? ""}
                                                onChange={newValue =>
                                                    props.onFormChange({
                                                        orderWorkType: newValue as OrderWorkType,
                                                    })
                                                }
                                            />
                                        </Item.Content>
                                    </Item.Root>

                                    {selectableCustomers.length > 1 && (
                                        <Item.Root>
                                            <Item.Label>Kunde</Item.Label>
                                            <Item.Content>
                                                <SingleCustomerSelect
                                                    customers={selectableCustomers}
                                                    error={workKindValidation.fields.customerOrder.error}
                                                    value={props.timeTracking?.order?.customerId}
                                                    onValueChange={handleCustomerChange}
                                                />
                                            </Item.Content>
                                        </Item.Root>
                                    )}
                                </>
                            )}

                            {workKind === "customer" && (
                                <Item.Root>
                                    <Item.Label>Feldzeit</Item.Label>
                                    <Item.Content>
                                        <SingleSelect
                                            placeholder="Feldzeit"
                                            data={fields.map(field => ({
                                                value: field.id,
                                                label: field.name,
                                            }))}
                                            value={selectedFieldId}
                                            onChange={handleFieldChange}
                                        />
                                    </Item.Content>
                                </Item.Root>
                            )}
                            <Item.Root>
                                <Item.Label>Notiz</Item.Label>
                                <Item.Content>
                                    <Input
                                        placeholder="Notiz"
                                        value={props.timeTracking?.note}
                                        onChange={event => {
                                            props.onFormChange({ note: event.currentTarget.value });
                                        }}
                                    />
                                </Item.Content>
                            </Item.Root>

                            {selectedOrder?.decoupleTimeTrackings && (
                                <Hint color="warning" severity="info" className="edit-time-tracking-modal__hint">
                                    {timeTrackingIsDecoupledOrderTimeTracking
                                        ? "Diese Zeiterfassung wirkt sich nicht auf die Mitarbeiterstunden aus, da die Zeiten entkoppelt sind."
                                        : "Diese Zeiterfassung wirkt sich nicht auf den Auftrag aus, da die Zeiten entkoppelt sind."}
                                </Hint>
                            )}

                            {props.isWorkKindLocked && workKind === "internal" && (
                                <Item.Root>
                                    <Item.Label>Intern</Item.Label>
                                    <Item.Content>
                                        <span>
                                            {props.timeTracking?.internalWorkType
                                                ? getInternalWorkTypeName(props.timeTracking.internalWorkType)
                                                : undefined}
                                        </span>
                                    </Item.Content>
                                </Item.Root>
                            )}
                            {props.isWorkKindLocked && workKind === "customer" && (
                                <Item.Root>
                                    <Item.Label>Auftrag</Item.Label>
                                    <Item.Content>
                                        <span>{currentOrder?.name ?? "Unbekannter Auftrag"}</span>
                                    </Item.Content>
                                </Item.Root>
                            )}
                        </FaItemGroup>
                    </form>
                </IonContent>
                <IonFooter>
                    <IonToolbar>
                        <IonButtons slot="start">
                            {props.role === "edit" && (
                                <IonButton color="danger" onClick={handleTimeTrackingDelete}>
                                    Löschen
                                </IonButton>
                            )}
                            <IonButton color="dark" onClick={handleDismiss}>
                                Verwerfen
                            </IonButton>
                        </IonButtons>
                        <IonButtons slot="end">
                            <IonButton color="primary" onClick={handleSubmit}>
                                Speichern
                            </IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonFooter>
            </div>
        </FarmActModal>
    );
};

type WorkKind = "customer" | "internal";

function getOrderQuery(timeTracking: TimeTracking | null): DocumentReference<Order> | undefined {
    if (!timeTracking?.order?.orderId) {
        return;
    }

    return Firebase.instance().getOrderRef(timeTracking.order.orderId);
}

function getWorkKind(timeTracking: TimeTracking | null): WorkKind {
    if (!timeTracking) {
        return "internal";
    }
    if (timeTracking.orderWorkType || timeTracking.order?.orderId) {
        return "customer";
    }

    return "internal";
}
