import { createContext, PropsWithChildren, useContext } from "react";
import { Order } from "@farmact/model/src/model/Order";
import { TimeTracking } from "@farmact/model/src/model/TimeTracking";
import { FieldMeta } from "@farmact/model/src/model/FieldMeta";
import { TrackMeta } from "@farmact/model/src/model/TrackMeta";
import Field from "@farmact/model/src/model/Field";
import { Track } from "@farmact/model/src/model/Track";
import { Marker } from "@farmact/model/src/model/Marker";
import { Customer } from "@farmact/model/src/model/Customer";
import { isOrderActive } from "../../../util/orderUtils";
import { useDocumentData } from "../../../firebase/dataHooks";
import { Firebase } from "../../../firebase";
import { useCustomerAndOrderMarkers } from "../utils/useCustomerAndOrderMarkers";
import { LiquidMixture } from "@farmact/model/src/model/LiquidMixture";

export interface IOrderContext {
    order: Order;
    orderFields: Field[];
    orderTracks: Track[];
    /**
     * Order markers and customer markers (not including field markers!)
     */
    orderAndCustomerMarkers: Marker[];
    /**
     * All available fields (including non-selected)
     */
    customerFieldsMeta: FieldMeta[];
    /**
     * All available tracks (including non-selected)
     */
    customerTracksMeta: TrackMeta[];
    /**
     * All time trackings of this order
     */
    timeTrackings: TimeTracking[];
    /**
     * Customer that is currently being worked for. null if order is not currently active or no customer is selected.
     */
    currentlyActiveCustomer: Customer | null;
    /**
     * Available liquid mixtures. Where a new order is being created, these are not yet persisted.
     */
    liquidMixtures: LiquidMixture[];
    /**
     * Call this function when a new valid liquid mixture should be added.
     * @param newLiquidMixture
     */
    onAddLiquidMixture: (newLiquidMixture: LiquidMixture) => void;
    /**
     * Call this function when n existing liquid mixture should be removed.
     * @param newLiquidMixtureId
     */
    onRemoveLiquidMixture: (liquidMixtureId: LiquidMixture["id"]) => void;
}

export const defaultOrderContext: IOrderContext = {
    order: new Order(),
    orderFields: [],
    orderTracks: [],
    orderAndCustomerMarkers: [],
    customerFieldsMeta: [],
    customerTracksMeta: [],
    timeTrackings: [],
    currentlyActiveCustomer: null,
    liquidMixtures: [],
    onAddLiquidMixture: () => { },
    onRemoveLiquidMixture: () => { },
};

export const OrderContext = createContext<IOrderContext>(defaultOrderContext);

type IOrderContextProviderProps = PropsWithChildren<Omit<IOrderContext, "currentlyActiveCustomer" | "orderAndCustomerMarkers">>;

export function OrderContextProvider(props: IOrderContextProviderProps) {
    const orderAndCustomerMarkers = useCustomerAndOrderMarkers(props.order);

    const currentlyActiveCustomerId =
        isOrderActive(props.order) && props.order.activeTime.length > 0
            ? props.order.activeTime[props.order.activeTime.length - 1].customerId
            : null;

    const [currentlyActiveCustomer = null] = useDocumentData(
        currentlyActiveCustomerId ? Firebase.instance().getCustomerRef(currentlyActiveCustomerId) : undefined,
        [currentlyActiveCustomerId]
    );

    return (
        <OrderContext.Provider value={{
            ...props,
            currentlyActiveCustomer,
            orderAndCustomerMarkers
        }}>{props.children}</OrderContext.Provider>
    );
}

export function useOrderContext() {
    return useContext(OrderContext);
}
