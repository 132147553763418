import { BillReminder } from "./BillReminder";
import { CustomerPaymentInfos, DEFAULT_CUSTOMER_PAYMENT_INFOS } from "./Customer";
import { PhotoSize } from "./PhotoSize";
import { Receipt } from "./Receipt";

export const DEFAULT_VAT_PERCENT_POINTS = 19;

export enum BillStatus {
    DRAFT = "DRAFT",
    OPEN = "OPEN",
    PAID = "PAID",
    OVERDUE = "OVERDUE",
}

export function getBillStatusName(status: BillStatus): string {
    switch (status) {
        case BillStatus.DRAFT:
            return "Entwurf";
        case BillStatus.PAID:
            return "Bezahlt";
        case BillStatus.OVERDUE:
            return "Überfällig";
        case BillStatus.OPEN:
            return "Offen";
    }
}

export type PartialPayment = {
    id: string;
    paymentDate: string;
    paymentAmount: number;
};

export const PLACEHOLDER_MANUALLY_BILLED = "__PLACEHOLDER_MANUALLY_BILLED__";

export class Bill extends Receipt {
    public status: BillStatus;
    public paymentDate: string | null;
    public paymentInfos: CustomerPaymentInfos;
    public numberOfBillReminders: number;
    public partialPayments: PartialPayment[];
    public printPaymentInfo: boolean;
    public createdFromDeliveryNoteId: string | null;
    public photoExportResolution: PhotoSize;

    constructor(initialValues?: Partial<Bill>) {
        super(initialValues);
        this.status = initialValues?.status ?? BillStatus.DRAFT;
        this.paymentDate = initialValues?.paymentDate ?? null;
        this.appendixExports = initialValues?.appendixExports ?? [];
        this.paymentInfos = {
            ...DEFAULT_CUSTOMER_PAYMENT_INFOS,
            ...initialValues?.paymentInfos,
        };
        this.partialPayments = initialValues?.partialPayments ?? [];
        this.numberOfBillReminders = initialValues?.numberOfBillReminders ?? 0;
        this.printPaymentInfo = initialValues?.printPaymentInfo ?? true;
        this.createdFromDeliveryNoteId = initialValues?.createdFromDeliveryNoteId ?? null;
        this.photoExportResolution = initialValues?.photoExportResolution ?? PhotoSize.M;
    }
}

export function isBill(receipt: Receipt): receipt is Bill {
    const receiptToCheck = receipt as Partial<Bill>;
    const receiptAsBillReminder = receipt as Partial<BillReminder>;
    return (
        typeof receiptToCheck.printPaymentInfo === "boolean" &&
        typeof receiptToCheck.status === "string" &&
        typeof receiptAsBillReminder.fee === "undefined"
    );
}
