import { Customer, CustomerPaymentInfos } from "@farmact/model/src/model/Customer";
import { OperatingUnit } from "@farmact/model/src/model/OperatingUnit";
import { Receipt } from "@farmact/model/src/model/Receipt";

export function getCustomerPaymentInfos(operatingUnit: OperatingUnit, customer?: Customer): CustomerPaymentInfos {
    return {
        paymentType: customer?.paymentType ?? operatingUnit.customerPaymentInfos.paymentType,
        paymentDueDays: customer?.paymentDueDays ?? operatingUnit.customerPaymentInfos.paymentDueDays,
        discountPercent: customer?.discountPercent ?? operatingUnit.customerPaymentInfos.discountPercent,
        cashDiscountPercent: customer?.cashDiscountPercent ?? operatingUnit.customerPaymentInfos.cashDiscountPercent,
        cashDiscountDueDays: customer?.cashDiscountDueDays ?? operatingUnit.customerPaymentInfos.cashDiscountDueDays,
    };
}

export function constructCustomerName(customer?: Customer) {
    if (!customer) {
        return "-";
    }
    return customer.name || [customer.contactPerson?.lastName, customer.contactPerson?.firstName].filter(Boolean).join(" ");
}

// TODO : move to core / utils package
export function constructCustomerNameForReceipt(receipt: Receipt | undefined) {
    if (!receipt) {
        return "-";
    }
    return receipt.billingAddress.name || receipt.customerContactPerson;
}
