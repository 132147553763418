import { array, boolean, Describe, enums, nullable, number, object, optional, string, union, lazy, literal } from "superstruct";
import { v4 } from "uuid";
import { OperatingUnit } from "./OperatingUnit";
import { ResourcePrice, ResourcePriceCalculationType, ResourcePriceVariantsType } from "./ResourcePrice";
import { PriceStructureType } from "./services/ServicePriceBlocks";

export enum ResourceUnit {
    LITER = "LITER",
    MILLILITER = "MILLILITER",
    CUBIC_METER = "CUBIC_METER",
    SPACE_CUBIC_METER = "SPACE_CUBIC_METER",
    BULK_CUBIC_METER = "BULK_CUBIC_METER",
    KILOGRAM = "KILOGRAM",
    GRAM = "GRAM",
    KILOMETER = "KILOMETER",
    TON = "TON",
    METER = "METER",
    PIECE = "PIECE",
    HOUR = "HOUR",
    DAY = "DAY",
    CARTRIDGE = "CARTRIDGE",
    SEED = "SEED",
    HECTARE = "HECTARE",
    SQUARE_METER = "SQUARE_METER",
    QUINTAL = "QUINTAL",
    KILO_WATT_HOUR = "KILO_WATT_HOUR",
    UNIT = "UNIT",
    FLATRATE = "FLATRATE",
    LEGACY_LITER = "Liter",
    LEGACY_KILOGRAM = "Kilogramm",
    LEGACY_PIECE = "Stück",
    LEGACY_CARTRIDGE = "Kartuschen",
}

export enum ResourceCategory {
    SPARE_PARTS = "SPARE_PARTS",
    FEED = "FEED",
    TRADING_GOODS = "TRADING_GOODS",
    LUBRICANTS_AND_FUELS = "LUBRICANTS_AND_FUELS",
    SEEDS_AND_FERTILIZERS = "SEEDS_AND_FERTILIZERS",
    OTHER = "OTHER",
    RECEIPT_POSITIONS = "RECEIPT_POSITIONS",
}

export class Resource {
    public id: string;
    public name: string;
    public unit: ResourceUnit;
    public category: ResourceCategory;
    public other: string;
    public archived: boolean;
    public vatPercentPoints: number | null;
    public billDescription: string | null;
    public inventoryEnabled: boolean;
    public inventoryCount: number;
    public accountNumber: number | null;
    public price: ResourcePrice<ResourcePriceCalculationType>;
    public operatingUnitIds: Array<OperatingUnit["id"]>;
    public vskz_mr: string | null;

    constructor(initialValues?: Partial<Resource>) {
        this.id = initialValues?.id ?? "";
        this.name = initialValues?.name ?? "";
        this.unit = initialValues?.unit ?? ResourceUnit.PIECE;
        this.category = initialValues?.category ?? ResourceCategory.OTHER;
        this.other = initialValues?.other ?? "";
        this.archived = initialValues?.archived ?? false;
        this.price = initialValues?.price ?? {
            calculationType: ResourcePriceCalculationType.FIXED,
            variantsType: ResourcePriceVariantsType.SIMPLE,
            pricePerUnit: {
                price: 0,
                type: PriceStructureType.SIMPLE,
            },
            costPerUnit: 0,
            id: v4(),
            name: "Standardpreis",
        };
        this.vatPercentPoints = initialValues?.vatPercentPoints ?? null;
        this.billDescription = initialValues?.billDescription ?? null;
        this.inventoryEnabled = initialValues?.inventoryEnabled ?? false;
        this.inventoryCount = initialValues?.inventoryCount ?? 0;
        this.accountNumber = initialValues?.accountNumber ?? null;
        this.operatingUnitIds = initialValues?.operatingUnitIds ?? [];
        this.vskz_mr = initialValues?.vskz_mr ?? null;
    }
}

const ResourcePriceStruct: Describe<ResourcePrice<ResourcePriceCalculationType>> = object({
    id: string(),
    name: string(),
    calculationType: enums(Object.values(ResourcePriceCalculationType)),
    variantsType: enums(Object.values(ResourcePriceVariantsType)),
    costPerUnit: optional(number()),
    pricePerUnit: optional(union([
        object({
            type: literal(PriceStructureType.SIMPLE),
            price: number()
        }),
        object({
            type: literal(PriceStructureType.TIERED),
            id: string(),
            price: array(object({
                id: string(),
                upperBound: union([number(), literal(null)]),
                price: number(),
            }))
        }),
        object({
            type: literal(PriceStructureType.VOLUME),
            id: string(),
            price: array(object({
                id: string(),
                upperBound: union([number(), literal(null)]),
                price: number(),
            }))
        }),
    ])),
    surchargePercent: optional(number()),
    variants: optional(lazy(() => array(ResourcePriceStruct)))
});


export const ResourceStruct: Describe<Resource> = object({
    id: string(),
    name: string(),
    unit: enums(Object.values(ResourceUnit)),
    category: enums(Object.values(ResourceCategory)),
    other: string(),
    archived: boolean(),
    vatPercentPoints: nullable(number()),
    billDescription: nullable(string()),
    inventoryEnabled: boolean(),
    inventoryCount: number(),
    accountNumber: nullable(number()),
    price: ResourcePriceStruct,
    operatingUnitIds: array(string()),
    vskz_mr: nullable(string()),
});
