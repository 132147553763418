import { Absence } from "@farmact/model/src/model/Absence";
import { Overtime } from "@farmact/model/src/model/Overtime";
import { TimeTracking } from "@farmact/model/src/model/TimeTracking";
import dayjs from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";
import { getTimeTrackingOverlapsWithDay } from "../../../../util/timeTrackingUtils";
import { DayGroupedWorkTimes } from "./TableGroup/TableGroupContext";
import { clipAbsence } from "../../../../util/absenceUtils";

dayjs.extend(isoWeek);

export function groupWorktimesByDay(
    timeTrackings: TimeTracking[],
    overtimes: Overtime[],
    absences: Absence[]
): DayGroupedWorkTimes[] {
    const dayTimestampsMs = new Set(
        timeTrackings.map(timeTracking => {
            return dayjs(timeTracking.startDateTime).startOf("day").valueOf();
        })
    );
    overtimes.forEach(overtime => dayTimestampsMs.add(dayjs(overtime.date).startOf("day").valueOf()));
    absences.forEach(absence => {
        absence.dates.forEach(date => dayTimestampsMs.add(dayjs(date).startOf("day").valueOf()));
    });

    const groups = Array.from(dayTimestampsMs).map(dayTimestampMs => {
        const absencesMap = new Map<string, Absence>();
        const date = new Date(dayTimestampMs);
        absences
            .filter(absence => absence.dates.includes(dayjs(date).format("YYYY-MM-DD")))
            .forEach(absence => {
                absencesMap.set(absence.id, absence);
            });

        return {
            day: date,
            timeTrackings: sortTimeTrackingsByStartTime(
                timeTrackings.filter(timeTracking => {
                    return getTimeTrackingOverlapsWithDay(date, timeTracking);
                })
            ),
            overtimes: overtimes.filter(overtime => dayjs(overtime.date).isSame(date, "day")),
            absences: [...absencesMap.values()],
        };
    });

    return sortWorktimeGroupsByDay(groups);
}

function sortTimeTrackingsByStartTime(timeTrackings: TimeTracking[]): TimeTracking[] {
    return [...timeTrackings].sort((a, b) => {
        return dayjs(a.startDateTime).valueOf() - dayjs(b.startDateTime).valueOf();
    });
}

function sortWorktimeGroupsByDay(groups: DayGroupedWorkTimes[]): DayGroupedWorkTimes[] {
    return [...groups].sort((a, b) => {
        return +b.day - +a.day;
    });
}

export interface CalendarWeekTimeTrackingSection {
    id: string;
    week: Week | null;
    year: Year | null;
    groups: DayGroupedWorkTimes[];
}

export function createSimpleWorktimeSections(
    timeTrackings: TimeTracking[],
    overtimes: Overtime[],
    absences: Absence[]
): CalendarWeekTimeTrackingSection[] {
    return [
        {
            id: "simple-time-tracking-section",
            week: null,
            year: null,
            groups: groupWorktimesByDay(timeTrackings, overtimes, absences),
        },
    ];
}

type Week = number;
type Year = number;
type WeekSplittedKey = `${Week}-${Year}`;

export function createWeekSplittedWorktimeSections(
    timeTrackings: TimeTracking[],
    overtimes: Overtime[],
    absences: Absence[]
): CalendarWeekTimeTrackingSection[] {
    const weekGroupedTimeTrackings = new Map<WeekSplittedKey, TimeTracking[]>();
    const weekGroupedOvertime = new Map<WeekSplittedKey, Overtime[]>();
    const weekGroupedAbsences = new Map<WeekSplittedKey, Absence[]>();
    const uniqueWeeks = new Set<WeekSplittedKey>();

    for (const timeTracking of timeTrackings) {
        const startTimeWeek = dayjs(timeTracking.startDateTime).isoWeek();
        const startTimeYear = dayjs(timeTracking.startDateTime).isoWeekYear();

        const key: WeekSplittedKey = `${startTimeWeek}-${startTimeYear}`;
        uniqueWeeks.add(key);

        weekGroupedTimeTrackings.set(key, [...(weekGroupedTimeTrackings.get(key) ?? []), timeTracking]);
    }
    overtimes.forEach(overtime => {
        const startTimeWeek = dayjs(overtime.date).isoWeek();
        const startTimeYear = dayjs(overtime.date).isoWeekYear();

        const key: WeekSplittedKey = `${startTimeWeek}-${startTimeYear}`;
        uniqueWeeks.add(key);
        weekGroupedOvertime.set(key, [...(weekGroupedOvertime.get(key) ?? []), overtime]);
    });
    absences.forEach(absence => {
        absence.dates.forEach(date => {
            const clippedAbsence = clipAbsence(absence, dayjs(date).startOf("week"), dayjs(date).endOf("week"));
            const isoWeek = dayjs(date).isoWeek();
            const isoWeekYear = dayjs(date).isoWeekYear();
            const key: WeekSplittedKey = `${isoWeek}-${isoWeekYear}`;
            uniqueWeeks.add(key);
            if (!(weekGroupedAbsences.get(key) ?? []).find(absence => absence.id === clippedAbsence.id)) {
                weekGroupedAbsences.set(key, [...(weekGroupedAbsences.get(key) ?? []), clippedAbsence]);
            }
        });
    });
    const sections = Array.from(uniqueWeeks).map(key => {
        const [week, year] = key.split("-");
        const timeTrackings = weekGroupedTimeTrackings.get(key) ?? [];
        const overtimes = weekGroupedOvertime.get(key) ?? [];
        const absences = weekGroupedAbsences.get(key) ?? [];

        return {
            id: `${week}-${year}`,
            week: parseInt(week),
            year: parseInt(year),
            groups: groupWorktimesByDay(timeTrackings, overtimes, absences),
        };
    });

    return sortTimeTrackingSections(sections);
}

function sortTimeTrackingSections(sections: CalendarWeekTimeTrackingSection[]): CalendarWeekTimeTrackingSection[] {
    return [...sections].sort((a, b) => {
        if (a.week === null || a.year === null) {
            return -1;
        } else if (b.week === null || b.year === null) {
            return 1;
        }

        const aDate = dayjs().year(a.year).isoWeek(a.week).toDate();
        const bDate = dayjs().year(b.year).isoWeek(b.week).toDate();

        return +bDate - +aDate;
    });
}
