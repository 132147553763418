import { Customer } from "@farmact/model/src/model/Customer";
import { useMemo } from "react";
import { Firebase } from "../../../../firebase";
import { query, where } from "firebase/firestore";
import { useMergedQueryData } from "../../../../firebase/utils/useMergedQueryData/useMergedQueryData";
import { useStableIdArray } from "../../../../util/customHooks/useStableArray";
import { useCollectionData } from "../../../../firebase/dataHooks";
import { getQueryForCustomersWithIds } from "../../../../firebase/utils/useMergedQueryData/queryGenerators/getQueryForCustomersWithIds";
import { useOrganizationContext } from "../../../organization/context/OrganizationContext";
import { THRESHOLD_FOR_LOADING_CUSTOMERS } from "../customerInputsUtils";

interface UseCustomersForCustomerSelectParams {
    controlledCustomers: Customer[] | undefined;
    currentSelection: Customer["id"][];
    search: string;
    filter?: (customer: Customer) => boolean;
}

type UseCustomersForCustomerSelectReturn = readonly [customer: Customer[], loading: boolean];

export function useCustomersForCustomerSelect(
    params: UseCustomersForCustomerSelectParams
): UseCustomersForCustomerSelectReturn {
    const { numberOfCustomers, numberOfCustomersLoading } = useOrganizationContext();
    const shouldFetchOptions = !params.controlledCustomers;

    const companyHasFewCustomers = !numberOfCustomersLoading && numberOfCustomers <= THRESHOLD_FOR_LOADING_CUSTOMERS;
    const lowercasedSearch = params.search.substring(0, 10).toLowerCase();

    const queryForAllCustomers = useMemo(() => {
        if (!shouldFetchOptions || !companyHasFewCustomers) {
            return undefined;
        }
        return Firebase.instance().getAllCustomers();
    }, [companyHasFewCustomers, shouldFetchOptions]);

    const [allCustomers, allCustomersLoading] = useCollectionData(queryForAllCustomers, [queryForAllCustomers]);

    const queryToFetchSearchedCustomers = useMemo(() => {
        if (!shouldFetchOptions || lowercasedSearch.length < 3 || companyHasFewCustomers) {
            return undefined;
        }
        return query(
            Firebase.instance().getAllCustomers(),
            where("_searchableSubstrings", "array-contains", lowercasedSearch)
        );
    }, [lowercasedSearch, shouldFetchOptions, companyHasFewCustomers]);
    const [searchedCustomers, searchedCustomersLoading] = useCollectionData(queryToFetchSearchedCustomers, [
        queryToFetchSearchedCustomers,
    ]);

    const stableCurrentSelectionArray = useStableIdArray(params.currentSelection);
    const [alreadySelectedCustomers, alreadySelectedCustomersLoading] = useMergedQueryData(
        stableCurrentSelectionArray,
        getQueryForCustomersWithIds
    );

    const baseCompatibleCustomers =
        params.controlledCustomers ?? (companyHasFewCustomers ? allCustomers : searchedCustomers);

    const filteredCompatibleCustomers = baseCompatibleCustomers.filter(params.filter ? params.filter : () => true);

    const deduplicatedCustomers = [...alreadySelectedCustomers, ...filteredCompatibleCustomers].filter(
        (outerCustomer, index, allCustomers) => {
            return allCustomers.findIndex(innerCustomer => outerCustomer.id === innerCustomer.id) === index;
        }
    );

    return [
        deduplicatedCustomers,
        searchedCustomersLoading || alreadySelectedCustomersLoading || allCustomersLoading,
    ] as const;
}
