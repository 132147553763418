import dayjs from "dayjs";
import { AppUser } from "./AppUser";
import { GasStation } from "./GasStation";
import { Machine } from "./Machine";
import { OperatingUnit } from "./OperatingUnit";
import { Order } from "./Order";

export enum ResourceUsageSource {
    COMPANY = "company",
    CUSTOMER = "customer",
    EXTERNAL = "external",
}

export class Refuel {
    public id: string;
    public date: string;
    public amount: number;
    public machineId: Machine["id"];
    public startAmount: number | null;
    public endAmount: number | null;
    public operatingHoursBeforeRefuel: number | null;
    public appUserId: AppUser["id"] | null;
    public gasStationId: GasStation["id"] | null;
    public archived: boolean;
    public resourceId: string | null;
    public resourceVariantId: string | null;
    public resourceName: string;
    public purpose: string | null;
    public orderId: Order["id"] | null;
    public orderName: string | null;
    public customerId: string | null;
    public employeeId: string;
    public operatingUnitId: OperatingUnit["id"] | null;

    constructor(initialValues?: Partial<Refuel>) {
        this.id = initialValues?.id ?? "";
        this.date = initialValues?.date ?? dayjs().toISOString();
        this.amount = initialValues?.amount ?? 0;
        this.archived = initialValues?.archived ?? false;
        this.startAmount = initialValues?.startAmount ?? 0;
        this.endAmount = initialValues?.endAmount ?? 0;
        this.operatingHoursBeforeRefuel = initialValues?.operatingHoursBeforeRefuel ?? null;
        this.appUserId = initialValues?.appUserId ?? null;
        this.gasStationId = initialValues?.gasStationId ?? null;
        this.resourceId = initialValues?.resourceId ?? null;
        this.resourceVariantId = initialValues?.resourceVariantId ?? null;
        this.resourceName = initialValues?.resourceName ?? "";
        this.purpose = initialValues?.purpose ?? null;
        this.orderId = initialValues?.orderId ?? null;
        this.orderName = initialValues?.orderName ?? null;
        this.customerId = initialValues?.customerId ?? null;
        this.employeeId = initialValues?.employeeId ?? "";
        this.operatingUnitId = initialValues?.operatingUnitId ?? null;
        this.machineId = initialValues?.machineId ?? "";
    }
}
